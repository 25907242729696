import { ReactFlowProvider, ReactFlow, Controls, Background } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import styles from './flow-designer.module.scss';
import {
  EditNodeContent,
  NodeCondition,
  NodeEmail,
  NodePlaceholder,
  NodeSms,
  NodeTimer,
  NodeTrigger,
  SaveUpdatedCampaign,
  SelectorNode,
} from './components';
import { useCampaign, useFlowDesigner } from './hooks';
import { _addMarketingCampaign, _getMarketingCampaign } from 'controllers/schools/schools_controller';
import { useSelector } from 'react-redux';
import { onCloseEditModal, onOpenSaveModal } from 'redux/campaigns/campaigns-reducer';
import { Modal } from 'antd';
import { CampaignBuilder } from '..';
import { getParam } from './utils/getParam';

export const FlowDesigner = ({ params }) => {
  return (
    <ReactFlowProvider>
      <Flow params={params} />
    </ReactFlowProvider>
  );
};

const Flow = ({ params }) => {
  const { id: schoolId, campaign_id: campaignId } = params;

  const { currentSchool } = useSelector((state) => state.school);

  const { campaign, setCampaignParam } = useCampaign();

  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    setRfInstance,
    onConnect,
    onDragOver,
    onDrop,
    onNodeClick,
    onSave,
    isEmptyNode,
  } = useFlowDesigner(campaign || {});

  const { isModalOpen, nodeId } = useSelector((state) => state.campaigns);

  const nodeTypes = {
    emailNode: NodeEmail,
    smsNode: NodeSms,
    timerNode: NodeTimer,
    conditionNode: NodeCondition,
    triggerNode: NodeTrigger,
    placeholderNode: NodePlaceholder,
  };

  const edgeOptions = {
    type: 'smoothstep',
  };

  const updateFlow = () => {
    const flow = onSave();

    const nodes = getParam(flow, 'nodes');
    const edges = getParam(flow, 'edges');
    const viewport = getParam(flow, 'viewport');

    setCampaignParam({ key: 'nodes', value: nodes.value });
    setCampaignParam({ key: 'edges', value: edges.value });
    setCampaignParam({ key: 'viewport', value: viewport.value });

    onOpenSaveModal();
  };

  return (
    <div className={styles.container}>
      <SelectorNode campaignType={campaign?.platform} onNodeClick={onNodeClick} isEmptyNode={isEmptyNode} />
      <div className={styles.canva}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onInit={setRfInstance}
          fitView
          fitViewOptions={{
            padding: 0.2,
            minZoom: 0.5,
            maxZoom: 1,
            duration: 500,
          }}
          minZoom={0.2}
          maxZoom={2}
          defaultViewport={{ x: 0, y: 0, zoom: 0.5 }}
          nodeTypes={nodeTypes}
          defaultEdgeOptions={edgeOptions}>
          <Controls />
          <Background />
        </ReactFlow>
      </div>
      {/* Control buttons */}
      <CampaignBuilder.Footer primaryLabel="Confirm and Save" onClick={updateFlow} />

      {/* Edit Content from node selected */}
      <Modal open={isModalOpen} onCancel={onCloseEditModal} width={950} footer={null}>
        <EditNodeContent nodeId={nodeId} campaign={campaign} currentSchool={currentSchool} />
      </Modal>

      <SaveUpdatedCampaign schoolId={schoolId} campaignId={campaignId} />
    </div>
  );
};
