import React, { Component, Fragment } from 'react';
import ImageUploadV2 from '../../../../imageUpload/v2/ImageUploadV2';
import Validation from 'components/form/Validation';
import {
  _uploadSchoolEmailCampaingImage,
  _uploadSchoolEmailCampaingImageV2,
} from 'controllers/schools/schools_controller';
import { connect } from 'react-redux';

import { getS3Bucket } from 'redux/school/school-actions';

const ALLOWED_EXTENSIONS = /(\.jpg|\.jpeg|\.png)$/i;
const IMG_DEFAULT =
  'https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/dashboard-crm/v3/campaigns/icon-custom-img.svg';
const MAX_SIZE_ALLOWED_BYTES = 5000000; // 5MB

const getPropsFromApplicationState = (state) => {
  return {
    getS3Bucket: state.school.getS3Bucket,
  };
};
class ImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = { error: {}, setWidth: window.innerWidth, s3Bucket: '' };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.loadUrlS3Bucket();

    // Resize
    const handleWindowResize = () => this.setState({ setWidth: window.innerWidth });
    window.addEventListener('resize', () => this.setState({ setWidth: window.innerWidth }, () => {}));

    return () => window.removeEventListener('resize', handleWindowResize);
  }

  async loadUrlS3Bucket() {
    const response = await this.props.dispatch(getS3Bucket());
    if (response.json && response.json.length > 0) {
      this.setState({ s3Bucket: response.json[0] });
    }
  }

  onSave = (file) => {
    const { school_id: schoolId, campaign, image_key: imageKey } = this.props;
    const { id } = campaign;

    return _uploadSchoolEmailCampaingImageV2(schoolId, id, file).then((res) => {
      const payload = {
        key: imageKey,
        value: res.url,
      };
      this.props.onSuccess(payload);
      return res.url;
    });
    // return _uploadSchoolEmailCampaingImage(school_id, id, file)
    //     .then(res => {
    //         const payload = {
    //             key: image_key,
    //             value: res.url
    //         }
    //         this.props.onSuccess(payload)
    //         return res.url
    //     })
  };

  validateFile = async (e) => {
    // old method
    const fileToUpload = document.getElementById(this.props.id);
    let { error } = this.state;
    if (fileToUpload && fileToUpload.files.length > 0) {
      const file = fileToUpload.files[0];

      if (!ALLOWED_EXTENSIONS.exec(file.name))
        error.image = [
          'We could not recognize the file type. Make sure it has an extension ending in .jpg, .jpeg or .png',
        ];

      if (file.size >= MAX_SIZE_ALLOWED_BYTES) error.image = ['File size too big. Please upload a smaller file.'];

      // TODO  Dimensions validation missing

      if (!error.image || error.image?.length == 0) {
        this.onSave(file).then((url) => this.setState({ url }));
      }
    }

    this.setState({ error });
  };

  newValidateFile = async (file) => {
    let error = {};

    if (!error.image || error.image?.length == 0) {
      this.onSave(file).then((url) => this.setState({ url }));
    }

    this.setState({ error });
  };

  getUrlImage = (image) => {
    if (image === null || image === undefined) return '';
    let mainUrl = image.split(`school/${this.props.school_id}/email-campaign/${this.props.campaign.id}/`)[0];
    let imageName = image.split(`school/${this.props.school_id}/email-campaign/${this.props.campaign.id}/`)[1];

    const DEFAULT_URL = image;

    let formatName = '';
    if (
      (imageName !== undefined && imageName.indexOf('l-') > -1) ||
      (imageName !== undefined && imageName.indexOf('m-') > -1) ||
      (imageName !== undefined && imageName.indexOf('s-') > -1)
    ) {
      formatName = imageName.substr(2);
    } else if (imageName !== undefined && imageName.indexOf('original-') > -1) {
      formatName = imageName.substr(9);
    } else {
      formatName = imageName;
    }

    let url = '';
    if (this.state.setWidth > 768 && this.state.setWidth < 1200) {
      url = `${mainUrl}school/${this.props.params.id}/email-campaign/${this.props.campaign.id}/l-${formatName}`;
    } else if (this.state.setWidth <= 768 && this.state.setWidth > 480) {
      url = `${mainUrl}school/${this.props.params.id}/email-campaign/${this.props.campaign.id}/m-${formatName}`;
    } else if (this.state.setWidth <= 480) {
      url = `${mainUrl}school/${this.props.params.id}/email-campaign/${this.props.campaign.id}/s-${formatName}`;
    } else {
      url = DEFAULT_URL;
    }

    return url;
  };

  render() {
    const { URL, size, disabled, remove_text: removeText, update_text: updatetext } = this.props;
    const { url } = this.state;

    const urlImg = url || URL || IMG_DEFAULT;

    const imageStyle = size == 'logo' ? { width: '95px' } : { width: '235px', height: '220px' };

    let images = [];
    if (urlImg != IMG_DEFAULT) {
      images = [
        {
          filename: urlImg,
          school_id: this.props.school_id,
          campaign_id: this.props.campaign.id,
        },
      ];
    }

    return (
      <>
        <div className="school-logo-content">
          {/*
                <div className={size == 'logo' ? "school-logo-image-content mr-2" : "school-image-campaing-content mr-2"}>
                    <div className={size == 'logo' ? "school-logo" : "school-image-campaign"}>
                        <img src={url_img} style={imageStyle} />
                    </div>
                </div>
                */}
          <div className="school-logo-actions">
            <div className="school-logo-buttons">
              <Validation messages={this.state.error?.image}>
                {/* {url_img == IMG_DEFAULT && */}
                <>
                  {/* <ImageUpload 
										id={this.props.id}
										multiple={false}
										needCrop={false}
										typeImage='.png, .jpg, .jpeg, .svg'
										sizeImage={2}
										styleCss={{minWidth: 320, height: 110}}
										maxWidth={size == 'logo' ? 200 : 300}
										maxHeight={size == 'logo' ? 200 : 300}
										profile_image={false}
										showFiles={false}
                                        onChange={this.newValidateFile}
									/> */}
                  <ImageUploadV2
                    id={this.props.id}
                    multiple={false}
                    maxFiles={1}
                    typeImage=".png, .jpg, .jpeg"
                    styleCss={{ minWidth: 320, height: 110 }}
                    aspectRatioValue={1 / 1}
                    selectAspectRatio={size == 'logo' ? ['1:1'] : ['16:9', '3:2', '1:1', '4:3', '18:6']}
                    onSave={this.newValidateFile.bind(this)}
                    images={images}
                    getUrlImage={this.getUrlImage.bind(this)}
                    isDeleted={false}
                    isLogoSchool
                    showPreview
                    s3Bucket={this.state.s3Bucket}
                    singleImage
                    hideEdit={images.length === 1 && images[0].filename.endsWith('/campaigns/school-logo.png')}
                  />
                  {urlImg != IMG_DEFAULT && (
                    <span
                      className={`${
                        this.props.title === 'Image'
                          ? 'remove-text-image d-flex align-items-center font-14'
                          : 'remove-text-logo d-flex align-items-center font-14'
                      }`}>
                      <span
                        onClick={(e) => !disabled && this.setState({ url: null }, () => this.props.onRemoveImage(e))}
                        style={{ cursor: 'pointer' }}>
                        <img
                          height="16"
                          className="mr-2"
                          src="https://scholamatch-static.s3-us-west-2.amazonaws.com/close.svg"
                        />
                        {removeText || 'Add Logo'}
                      </span>
                    </span>
                  )}
                </>
                {/* } */}
              </Validation>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(getPropsFromApplicationState)(ImageUploader);
