import { InputCampaign } from '../input-campaign';
import { FacebookOutlined, YoutubeOutlined, TwitterOutlined, InstagramOutlined } from '@ant-design/icons';
import styles from './social-media-links.module.scss';

export const SocialMediaLinks = ({ layout, getParamValue, onChangeParams }) => {
  const { MEDIA_FACEBOOK_KEY, MEDIA_INSTAGRAM_KEY, MEDIA_TWITTER_KEY, MEDIA_YOUTUBE_KEY } = layout?.keys;

  return (
    <div className={styles.socialMediaGrid}>
      <SocialMediaInput
        id={MEDIA_FACEBOOK_KEY}
        icon={<FacebookOutlined style={{ color: '#2563eb' }} />}
        value={getParamValue(MEDIA_FACEBOOK_KEY)}
        onChangeParams={onChangeParams}
      />
      <SocialMediaInput
        id={MEDIA_TWITTER_KEY}
        icon={<TwitterOutlined style={{ color: '#60a5fa' }} />}
        value={getParamValue(MEDIA_TWITTER_KEY)}
        onChangeParams={onChangeParams}
      />
      <SocialMediaInput
        id={MEDIA_INSTAGRAM_KEY}
        icon={<InstagramOutlined style={{ color: '#dc2b79' }} />}
        value={getParamValue(MEDIA_INSTAGRAM_KEY)}
        onChangeParams={onChangeParams}
      />
      <SocialMediaInput
        id={MEDIA_YOUTUBE_KEY}
        icon={<YoutubeOutlined style={{ color: '#ff0000' }} />}
        value={getParamValue(MEDIA_YOUTUBE_KEY)}
        onChangeParams={onChangeParams}
      />
    </div>
  );
};

const SocialMediaInput = ({ id, icon, value, onChangeParams }) => {
  return (
    <div className={styles.socialMediaInput}>
      {icon}
      <InputCampaign id={id} value={value} onChange={onChangeParams} />
    </div>
  );
};
