import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dataUrlToFile from 'util/dataUrlToFile';
import ModalCrop from './ModalCrop';

// components
import PreviewImage from './PreviewImage';

const DEFAULT_RATIO = '1/1';

const getRatioArray = {
  '1:1': 1 / 1,
  '3:2': 3 / 2,
  '4:3': 4 / 3,
  '16:9': 16 / 9,
  '18:6': 18 / 6,
};

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
  justifyContent: 'center',
};

const aspectRatioStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

const aspectRatioInputStyle = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
};

const ImageUploadV2 = (props) => {
  // States
  const [files, setFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [imageFile, setImageFile] = useState({
    imageSrc: null,
    imageFilename: null,
    imageResult: null,
    imageError: null,
    contentType: null,
  });

  const [aspectRatioValue, setAspectRatioValue] = useState(null);
  const [selectAspectRatio, setSelectAspectRatio] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);

  // Ref
  const cropperRef = useRef();
  const previewRef = useRef();

  useEffect(() => {
    if (props && props.aspectRatioValue && props.aspectRatioValue != null) setAspectRatioValue(props.aspectRatioValue);
    if (props && props.selectAspectRatio && props.selectAspectRatio != null)
      setSelectAspectRatio(props.selectAspectRatio);
  }, []);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const imageCropper = {
    'image-cropper': true,
    [`${props.className}`]: props.className,
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: props.typeImage,
    multiple: props.multiple,
    disabled: !!(props.maxFiles && files.length >= props.maxFiles),
    onDrop: (acceptedFiles) => {
      let maxSize = (props.sizeImage || 0) * 1048576;
      let filesArray = [];
      setErrorMessage(false);
      if (acceptedFiles.length > 0) {
        acceptedFiles.map((file) => {
          if (props.sizeImage && file.size > maxSize) {
            setImageFile({
              ...imageFile,
              imageError: `File size must be less than ${props.sizeImage}MB`,
            });
          } else {
            filesArray.push(file);
          }
        });

        if (filesArray.length === 1) {
          let file = filesArray[0];
          setImageFile({
            imageID: null,
            imageSrc: URL.createObjectURL(file),
            imageFilename: file.name,
            imageResult: null,
            imageError: null,
            contentType: file.type,
          });
          setShowModal(true);
        } else if (filesArray.length > 0) {
          saveFiles(filesArray);
        }
      } else {
        setImageFile({
          ...imageFile,
          imageError: 'File must be an image',
        });
      }
    },
  });

  const closeModal = () => {
    setShowModal(!showModal);
  };

  const deleteImage = (file) => {
    setErrorMessage(false);
    props.onDelete(file.id);
  };

  const createBase64 = async (url) => {
    const blob = await url.blob();
    const imageBase64 = new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
    if (imageBase64 !== undefined) {
      return await imageBase64;
    }
  };

  const editCropImage = async (file) => {
    try {
      setErrorMessage(false);
      let urlFile = file.filename.split('/');
      let fileName = urlFile.pop();

      let urlNormalFile = '';
      let urlOriginalfile = '';

      if (urlFile.indexOf('https:') > -1) {
        urlOriginalfile = `${urlFile.join('/')}/original-${fileName}`;
        urlNormalFile = `${urlFile.join('/')}/${fileName}`;
      } else {
        urlOriginalfile = `https:${urlFile.join('/')}/original-${fileName}`;
        urlNormalFile = `https:${urlFile.join('/')}/${fileName}`;
      }

      urlFile = urlOriginalfile;
      let urlResponse = await fetch(`${urlOriginalfile}?t=${new Date().getTime()}`, { method: 'GET', mode: 'cors' });

      if (urlResponse.status !== 200) {
        urlFile = urlNormalFile;
        urlResponse = await fetch(`${urlNormalFile}?t=${new Date().getTime()}`, { method: 'GET', mode: 'cors' });
      }

      if (urlResponse.status === 200) {
        let contentType = urlResponse.headers.get('Content-Type');
        setImageFile({
          imageID: file?.id || null,
          imageSrc: urlFile,
          imageFilename: fileName,
          imageResult: null,
          imageError: null,
          contentType,
        });
        setShowModal(true);
      } else {
        setErrorMessage(true);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(true);
    }
  };

  const saveFiles = (files) => {
    let file = [];
    files.map((f) => {
      const reader = new FileReader();
      reader.onload = () => {
        file = dataUrlToFile(reader.result, f.name);
        file.original = dataUrlToFile(reader.result, f.name);
        props.onSave(file, props.profile_image, props.name);
      };
      reader.readAsDataURL(f);
    });
  };

  const saveCropImage = async () => {
    try {
      let file = [];
      let src = '';
      const url = await fetch(imageFile.imageSrc);
      if (cropperRef.current !== undefined && typeof cropperRef.current.getCroppedCanvas() !== 'undefined') {
        var tempUrl;
        if (imageFile.contentType) {
          if (imageFile.contentType === 'application/octet-stream') {
            tempUrl = cropperRef.current.getCroppedCanvas().toDataURL('image/jpeg');
          } else {
            tempUrl = cropperRef.current.getCroppedCanvas().toDataURL(imageFile.contentType);
          }
        } else {
          tempUrl = cropperRef.current.getCroppedCanvas().toDataURL();
        }

        file = dataUrlToFile(tempUrl, imageFile.imageFilename);

        src = await createBase64(url);
        file.original = dataUrlToFile(src, imageFile.imageFilename);

        if (imageFile && imageFile.imageID) {
          props.isDeleted && props.onDelete(imageFile.imageID);
        }
        props.onSave(file, props.profile_image, props.name);
        cropperRef.current != null && cropperRef.current.clear();
        setShowModal(false);
      }
    } catch (error) {
      console.log(error);
      setShowModal(false);
      setErrorMessage(true);
    }
  };

  const selectAspact = (value) => {
    if (value) {
      setAspectRatioValue(getRatioArray[value]);
    } else {
      setAspectRatioValue(16 / 9);
    }
  };

  const showDropZone = () => {
    if (props.singleImage === true) {
      let images = props.images;
      if (props.profile_image) {
        images = [];
        if (props.images && props.images.length > 0) {
          props.images.map((file, index) => {
            if (file.profile_image) {
              images.push(file);
            }
          });
        }
      }
      if (images && images.length > 0) {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      {showDropZone() === true && (
        <div className={classNames(imageCropper)}>
          <div className="dropzoneTwo">
            <div {...getRootProps({ className: 'dropzone' })} style={props.styleCss}>
              <div>
                <div className="icon-upload-file d-flex justify-content-center">
                  <i className="fa fa-cloud-upload" aria-hidden="true" />
                </div>
              </div>
              <>
                <h4>{props.inputText}</h4>
                {props.typeImage === 'image/*' ? (
                  <p>Accepted all image types</p>
                ) : (
                  <p>Accepted file types: {props.typeImage}</p>
                )}
                {props.sizeImage && <p>Max file size: {props.sizeImage}MB</p>}
                <input id={props.id} name={props.name} {...getInputProps()} />
                {imageFile.imageError != null ? (
                  <div
                    className={`${
                      props.styleCss && props.styleCss.width <= 200 ? 'validation-message-error' : 'validation-message'
                    }`}>
                    Error: {imageFile.imageError}
                  </div>
                ) : null}
              </>
            </div>
          </div>
        </div>
      )}
      {props.selectAspectRatio && props.selectAspectRatio.length > 1 && (
        <div className="select-aspect-ratio">
          <p style={{ marginLeft: '-1rem' }}>Please select a aspect ratio:</p>
          <br />
          <div style={aspectRatioStyle}>
            {props.selectAspectRatio.map((x, i) => {
              return (
                <div key={i} style={aspectRatioInputStyle}>
                  <label htmlFor="aspect">{x} </label>
                  <input
                    style={{ width: 16 }}
                    type="radio"
                    name="aspect"
                    checked={aspectRatioValue === getRatioArray[x]}
                    onChange={() => {}}
                    onClick={() => selectAspact(x)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      <section className="container-logo" style={{ paddingLeft: 0 }}>
        <aside style={thumbsContainer}>
          {props.images &&
            props.images.length > 0 &&
            props.images.map((file, index) => {
              if (file.profile_image === props.profile_image) {
                return (
                  <PreviewImage
                    previewRef={previewRef}
                    key={index}
                    file={file}
                    profile_image={props.profile_image}
                    closeModal={() => closeModal()}
                    editCropImage={() => editCropImage(file)}
                    deleteImage={() => deleteImage(file)}
                    getUrlImage={props.getUrlImage}
                    isDeleted={props.isDeleted}
                  />
                );
              }

              if (props.isLogoSchool && props.showPreview) {
                return (
                  <PreviewImage
                    previewRef={previewRef}
                    hideEdit={props.hideEdit}
                    key={index}
                    file={file}
                    profile_image={props.profile_image}
                    closeModal={() => closeModal()}
                    editCropImage={() => editCropImage(file)}
                    deleteImage={() => deleteImage(file)}
                    getUrlImage={props.getUrlImage}
                    isDeleted={props.isDeleted}
                  />
                );
              }
            })}
        </aside>
        {errorMessage && <p style={{ color: 'red' }}>something went wrong, try later</p>}
        <ModalCrop
          cropperRef={cropperRef}
          aspectRatioValue={aspectRatioValue}
          imageFile={imageFile}
          showModal={showModal}
          saveCropImage={() => saveCropImage()}
          closeModal={() => closeModal()}
        />
      </section>
    </>
  );
};

ImageUploadV2.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  inputText: PropTypes.string,
  sizeImage: PropTypes.number,
  styleCss: PropTypes.object,
  typeImage: PropTypes.string,
  profile_image: PropTypes.bool,
  multiple: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  maxFiles: PropTypes.number,
  isDeleted: PropTypes.bool,
  hideEdit: PropTypes.bool,
  showPreview: PropTypes.bool,
  s3Bucket: PropTypes.string,
  singleImage: PropTypes.bool,
};

ImageUploadV2.defaultProps = {
  inputText: 'Drag-n-drop a file or click to add an image',
  sizeImage: undefined,
  styleCss: { width: '100%', height: 120 },
  typeImage: 'image/*',
  profile_image: false,
  multiple: false,
  maxFiles: 5,
  isDeleted: true,
  hideEdit: false,
  showPreview: true,
  singleImage: false,
};

export default ImageUploadV2;
