import React, { Component, Fragment } from 'react';
import LayoutSelection from './LayoutSelection';
import { campaingContentCheck } from '../../utils/campaign_utils';
import { isBlank } from 'util/validate';
import schoolUrl from 'util/schoolUrl';
import config from 'config';
import HtmlParser from 'html-react-parser';
import { quillDecodeIndent } from '../../../../../util/react-quill-fix-lists';
import { FacebookFilled, InstagramFilled, TwitterSquareFilled, YoutubeFilled } from '@ant-design/icons';

const DEFAULT_HEADER_TEXT = 'Today is special and we want to celebrate with you!';
const DEFAULT_HEADER_MSG_TEXT =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam neque, aenean urna nisl fusce. Nunc dignissim adipiscing mauris scelerisque nam';

const PRIMARY_COLOR_KEY = '{{primary_color}}';
const SECONDARY_COLOR_KEY = '{{secondary_color}}';

export default class CDPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getParamValue = (key) => this.props.campaign?.params?.find((p) => p.key == key)?.value;

  renderImages = (keys) => {
    const { EMAIL_IMAGE_1_KEY, EMAIL_IMAGE_2_KEY, EMAIL_IMAGE_3_KEY } = keys;
    let columns = 1;
    if (EMAIL_IMAGE_2_KEY) columns++;
    if (EMAIL_IMAGE_3_KEY) columns++;

    const UrlImageKey1 = this.getParamValue(EMAIL_IMAGE_1_KEY);
    const UrlImageKey2 = this.getParamValue(EMAIL_IMAGE_2_KEY);
    const UrlImageKey3 = this.getParamValue(EMAIL_IMAGE_3_KEY);

    return (
      <div className="d-flex d-wraper justify-content-center row">
        {UrlImageKey1 != undefined && (
          <div
            className={`d-flex flex-column ${
              columns == 1 ? 'col-10' : columns == 2 ? 'col-6' : columns == 3 ? 'col-4' : ''
            }`}>
            <div className="email-image-preview w-100 m-auto">
              <div className="email-image">
                <img src={UrlImageKey1} className="w-100" />
              </div>
            </div>
          </div>
        )}
        {UrlImageKey2 != undefined && (
          <div
            className={`d-flex flex-column ${
              columns == 1 ? 'col-10' : columns == 2 ? 'col-6' : columns == 3 ? 'col-4' : ''
            }`}>
            <div className="email-image-preview w-100 m-auto">
              <div className="email-image">
                <img src={UrlImageKey2} className="w-100" />
              </div>
            </div>
          </div>
        )}
        {UrlImageKey3 != undefined && (
          <div
            className={`d-flex flex-column ${
              columns == 1 ? 'col-10' : columns == 2 ? 'col-6' : columns == 3 ? 'col-4' : ''
            }`}>
            <div className="email-image-preview w-100 m-auto">
              <div className="email-image">
                <img src={UrlImageKey3} className="w-100" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  renderTextBlocks = (keys) => {
    const { MESSAGE_TEXT_KEY, MESSAGE_2_KEY, MESSAGE_3_KEY } = keys;
    let columns = 1;
    if (MESSAGE_2_KEY) columns++;
    if (MESSAGE_3_KEY) columns++;

    const _render = (columns, key) => {
      const alingKey = `{{ALING_${key.replace(/{/gi, '').replace(/}/gi, '')}}}`;
      const aling = this.getParamValue(alingKey);
      let textContent = this.getParamValue(key) || DEFAULT_HEADER_MSG_TEXT;
      textContent = quillDecodeIndent(textContent);
      textContent = textContent.replace(/\r?\n/g, ' <br />');

      return (
        <div
          className={
            `d-flex flex-column ${columns}` == 1 ? 'col-10' : columns == 2 ? 'col-6' : columns == 3 ? 'col-4' : ''
          }>
          <div className={`email-text-body-preview pt-4 w-100 text-${aling || 'left'}`}>
            <div className="font-12 reset-styles">{HtmlParser(textContent)}</div>
          </div>
        </div>
      );
    };

    return (
      <div className="d-flex d-wraper justify-content-center row">
        {MESSAGE_TEXT_KEY && _render(columns, MESSAGE_TEXT_KEY)}
        {MESSAGE_2_KEY && _render(columns, MESSAGE_2_KEY)}
        {MESSAGE_3_KEY && _render(columns, MESSAGE_3_KEY)}
      </div>
    );
  };

  getSchoolApplicationURL = (schoolUrlPath) => {
    const {
      school: { externalapplication_url: externalapplicationUrl, has_premium_access: hasPremiumAccess },
    } = this.props;

    if (!isBlank(externalapplicationUrl) && hasPremiumAccess) return externalapplicationUrl;
    return `${schoolUrlPath}/application`;
  };

  renderCallToAction = (keys) => {
    const { campaign } = this.props;
    const {
      PRIMARY_COLOR_KEY,
      SECONDARY_COLOR_KEY,
      CTA_TYPE_KEY,
      CTA_CUSTOM_BTN_TEXT_KEY,
      CTA_CUSTOM_PROTO_KEY,
      CTA_CUSTOM_URL_KEY,
    } = keys;

    const ctaType = this.getParamValue(CTA_TYPE_KEY);
    if (ctaType == 'none') return null;
    if (!this.props.school) return null;
    // Colors
    const pColor = this.getParamValue(PRIMARY_COLOR_KEY);
    const sColor = this.getParamValue(SECONDARY_COLOR_KEY);
    const _style = {
      backgroundColor: pColor,
      borderColor: pColor,
      color: sColor,
    };

    const schoolUrlPath = config.searchHost + schoolUrl.build(this.props.school);
    const applicationUrlForView = this.props.applicationUrlForTest;

    let btnText = '';
    let btnUrl = '';
    switch (ctaType) {
      case 'request_info':
        btnText = campaign.audience_language === 'es' ? 'Solicitar Info' : 'Request Info';
        btnUrl = `${schoolUrlPath}?moreInfo=1`;
        break;
      case 'schedule_tour':
        btnText = campaign.audience_language === 'es' ? 'Agendar Visita' : 'Schedule Tour';
        btnUrl = `${schoolUrlPath}?scheduleTour=1`;
        break;
      case 'enroll_now':
        btnText = campaign.audience_language === 'es' ? 'Preinscribir Ahora' : 'Enroll Now';
        btnUrl = applicationUrlForView;
        break;
      case 'apply_now':
        btnText = campaign.audience_language === 'es' ? 'Aplicar Ahora' : 'Apply Now';
        btnUrl = applicationUrlForView;
        break;
      case 'custom':
        btnText = this.getParamValue(CTA_CUSTOM_BTN_TEXT_KEY);
        btnUrl =
          (this.getParamValue(CTA_CUSTOM_PROTO_KEY) || 'http://') +
          this.removeProtocol(this.getParamValue(CTA_CUSTOM_URL_KEY));
        break;
    }

    const ctaButton =
      btnText != '' ? (
        <div className="d-flex flex-column col-10 m-auto">
          <div className="d-flex justify-content-center pt-5">
            {btnUrl !== '' && (
              <a href={btnUrl} target="_blank" className="btn" style={_style} rel="noreferrer">
                {btnText}
              </a>
            )}
            {btnUrl === '' && (
              <a className="btn" style={_style}>
                {btnText}
              </a>
            )}
          </div>
        </div>
      ) : null;

    return ctaButton;
  };

  removeProtocol = (value) => {
    if (!value) {
      value = '';
    }
    var replaceMask = '';
    var regEx = /http:\/\//gi;
    value = value.replace(regEx, replaceMask);

    regEx = /https:\/\//gi;
    value = value.replace(regEx, replaceMask);
    return value.trim();
  };

  renderSignature = (keys) => {
    const { SIGNATURE_TYPE_KEY, SIGNATURE_LABEL_KEY, SIGNATURE_NAME_KEY } = keys;
    const type = this.getParamValue(SIGNATURE_TYPE_KEY);
    const label = this.getParamValue(SIGNATURE_LABEL_KEY);

    const name = this.getParamValue(SIGNATURE_NAME_KEY);

    if (type === 'none') return <></>;

    return (
      <div className="d-flex flex-column col-10 m-auto">
        <div className="d-flex flex-column align-items-center pt-5">
          <p className="pb-0">{label},</p>
          <p className="font-weight-bold">{name}</p>
        </div>
      </div>
    );
  };

  renderEmail = () => {
    const { campaign, layout, className, hideChangeLayout, school } = this.props;
    const keys = layout?.keys;

    if (!keys) return <></>;

    const pColor = this.getParamValue(PRIMARY_COLOR_KEY);
    const sColor = this.getParamValue(SECONDARY_COLOR_KEY);

    const campaignChecks = campaingContentCheck(campaign, layout?.params || []);
    const isMediaRedy =
      campaignChecks &&
      (campaignChecks.facebook || campaignChecks.instagram || campaignChecks.twitter || campaignChecks.youtube);

    return (
      <>
        <div className={className || 'email-sidebar'}>
          <div className="email-preview-content mb-24">
            <div className="email-image-preview-content email-content">
              <div className="school-logo-preview">
                <div>
                  <img src={this.getParamValue(keys.SCHOOL_LOGO_KEY)} style={{ width: '51px' }} />
                </div>
              </div>
              <div className="email-text-preview text-center m-auto">
                <p className="font-16 font-600">{this.getParamValue(keys.HEADER_TEXT_KEY) || DEFAULT_HEADER_TEXT}</p>
              </div>
              {/*  */}
              <div className="mx-auto mb-5">
                {this.renderImages(keys)}
                {this.renderTextBlocks(keys)}
                {this.renderCallToAction(keys)}
                {this.renderSignature(keys)}
              </div>
              <div
                className="preview-footer w-100 d-flex flex-column align-items-center justify-content-center"
                style={{ backgroundColor: pColor, color: sColor }}>
                <div className={isMediaRedy ? 'mx-auto pt-4 pb-3' : 'd-none'}>
                  {campaignChecks?.facebook && (
                    <span className="m-2">
                      <FacebookFilled />
                    </span>
                  )}
                  {campaignChecks?.twitter && (
                    <span className="m-2">
                      <TwitterSquareFilled />
                    </span>
                  )}
                  {campaignChecks?.instagram && (
                    <span className="m-2">
                      <InstagramFilled />
                    </span>
                  )}
                  {campaignChecks?.youtube && (
                    <span className="m-2">
                      <YoutubeFilled />
                    </span>
                  )}
                </div>
                <span className="py-4" style={{ fontSize: '12px' }}>
                  Powered by{' '}
                  <a href="https://schola.com" style={{ color: sColor }} target="_blank" rel="noreferrer">
                    Schola®
                  </a>{' '}
                  | Copyright © 2023{' '}
                </span>
              </div>
            </div>
          </div>
          {/* {!hideChangeLayout && (
            <div className="d-flex">
              <div className="layout-image">
                <img src="https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/dashboard-crm/v3/campaigns/icon-layout-blue.svg" />
              </div>
              <div className="layout-text ml-2">
                <p className="font-14 mb-2">
                  <span className="font-600">Layout:</span> {layout.name}
                </p>
                <p
                  className="select-layout text-primary-blue font-600 "
                  onClick={() => this.setState({ showLayoutSelection: true })}>
                  Select a different layout
                </p>
                <LayoutSelection
                  show={this.state.showLayoutSelection}
                  school={this.props.school}
                  onSave={(layoutId) => {
                    this.setState({ showLayoutSelection: false });
                    this.props.onChangeLayout(layoutId);
                  }}
                  onCancel={() => this.setState({ showLayoutSelection: false })}
                />
              </div>
            </div>
          )} */}
        </div>
      </>
    );
  };

  renderSms = () => {
    const { campaign, layout } = this.props;
    const keys = layout?.keys;

    if (!keys) return <></>;
    const _style = {
      backgroundImage:
        "url('https://scholamatch-static.s3-us-west-2.amazonaws.com/email-campaing/132561399_720972172165301_658863699655219332_n.png')",
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      minHeight: '350px',
      width: '100%',
    };
    const NAME_KEY = '{{from_name}}';
    const MESSAGE_KEY = '{{message_text}}';
    const SMS_URL_LINK_KEY = '{{sms_url_link}}';
    const SMS_IMAGE_KEY = '{{email_image}}';

    const { SIGNATURE_TYPE_KEY, SIGNATURE_LABEL_KEY, SIGNATURE_NAME_KEY } = keys;
    const type = this.getParamValue(SIGNATURE_TYPE_KEY);
    const label = this.getParamValue(SIGNATURE_LABEL_KEY);
    const name = this.getParamValue(SIGNATURE_NAME_KEY);

    const from = this.getParamValue(NAME_KEY);
    const message = this.getParamValue(MESSAGE_KEY);
    const urlLink = this.getParamValue(SMS_URL_LINK_KEY);
    const smsIamge = this.getParamValue(SMS_IMAGE_KEY);

    return (
      <>
        <div className="email-sidebar panel">
          <div className="email-preview-content mb-24">
            <div className="email-image-preview-content">
              <div className="mobile-preview">
                <div className="sms-from">
                  <p>{from}</p>
                </div>
                <div className="mobile-preview-container">
                  <div className="sms-body p-1">
                    <p style={{ fontSize: '12px' }}>
                      {message}
                      {type != 'none' && !isBlank(label) && !isBlank(name) && (
                        <>
                          <span className="d-inline-block">{label},</span>
                          <br />
                          <span className="d-inline-block">{name}</span>
                        </>
                      )}
                      {!isBlank(urlLink) && (
                        <a className="col-12 pl-0 d-inline-block text-truncate" href={urlLink}>
                          {urlLink}
                        </a>
                      )}

                      {!isBlank(smsIamge) && <img src={smsIamge} className="w-100" />}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const { platform } = this.props;
    if (platform == 'email') return this.renderEmail();
    if (platform == 'sms') return this.renderSms();
    return <></>;
  }
}
