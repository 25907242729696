import { NodeWrapper } from '.';
import styles from '../flow-designer.module.scss';
import { MailOutlined } from '@ant-design/icons';
import campaingConstants from 'components/pages/campaigns/utils/CampaingConstants';
import { Input } from 'antd';
import { useUpdateNode } from '../hooks';

export const NodeEmail = ({ data, id }) => {
  const subject = campaingConstants.SUBJECT_KEY;
  const previewText = data.find((item) => item.key === subject);
  const { handleNodeParam } = useUpdateNode();

  return (
    <NodeWrapper isFirstNode={id === 'start'}>
      <NodeWrapper.Header id={id} Icon={MailOutlined}>
        Send Email
      </NodeWrapper.Header>
      <Input
        name={subject}
        value={previewText.value}
        onChange={({ target }) => handleNodeParam({ nodeId: id, key: target.name, value: target.value })}
      />
    </NodeWrapper>
  );
};

export const ContentPreview = ({ children }) => {
  return (
    <div className={styles.emailNode}>
      <div className={styles.previewText}>{children}</div>
    </div>
  );
};
