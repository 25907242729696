import React from 'react';
import { NodeWrapper } from '.';
import { ThunderboltOutlined } from '@ant-design/icons';
import { Select, Typography, Flex } from 'antd';
import { useUpdateNode } from '../hooks';
import styles from './node-trigger.module.scss';

const options = [
  { value: 'real-time', label: 'Real-Time: Start Immediately' },
  { value: 'schedule', label: 'Schedule for Later' },
];

export const NodeTrigger = ({ data, id }) => {
  const { onChangeNode } = useUpdateNode();

  const handleTrigger = (value) => {
    onChangeNode(id, 'trigger', value);
  };

  return (
    <NodeWrapper isFirstNode>
      <NodeWrapper.Header id={id} Icon={ThunderboltOutlined} hideActions>
        Trigger
      </NodeWrapper.Header>
      <Flex vertical gap="middle">
        <div>
          <Typography.Paragraph className={styles.triggerText}>When should this campaign start?</Typography.Paragraph>
          <Select
            className="nodrag"
            defaultValue="lead-created"
            value={data.trigger}
            onChange={handleTrigger}
            options={options}
            style={{
              width: '100%',
            }}
            size="large"
          />
          <div className={styles.triggerDescription}>
            <Typography.Text>
              {data.trigger === 'real-time'
                ? 'New leads will automatically enter this campaign as soon as they match your audience filters.'
                : 'Send to all matching leads at your specified date and time.'}
            </Typography.Text>
          </div>
        </div>
      </Flex>
    </NodeWrapper>
  );
};
