import { useEffect, useRef } from 'react';
import { useToggle } from 'hooks/useToggle';
import { Flex, Input, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import styles from './campaign-name.module.scss';

export const CampaignName = ({ name, updateCampaignValue }) => {
  const [isEditing, toggleEditing] = useToggle(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleChange = (e) => {
    const { selectionStart, selectionEnd } = e.target;

    updateCampaignValue({ key: 'name', value: e.target.value });

    // Maintain cursor position after state update
    requestAnimationFrame(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(selectionStart, selectionEnd);
      }
    });
  };

  return (
    <Flex gap="middle" align="center">
      <Input
        ref={inputRef}
        disabled={!isEditing}
        className={styles.inputTitle}
        value={name}
        variant="borderless"
        onChange={handleChange}
        style={{ fontSize: '22px' }}
      />
      <Button size="large" type="default" onClick={toggleEditing} icon={<EditOutlined />} />
    </Flex>
  );
};
