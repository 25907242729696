import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class Text extends Component {
  render() {
    const textClasses = {
      text: true,
      'text--disabled': this.props.disabled,
      [`${this.props.className}`]: this.props.className,
    };

    return (
      <label className={classNames(textClasses)} htmlFor={this.props.id} disabled={this.props.disabled}>
        {this.props.label ? <span className="text-label">{this.props.label}</span> : null}
        {this.props.textarea ? (
          <>
            <textarea
              ref={(input) => {
                this.textInputRef = input;
              }}
              className="text-area"
              id={this.props.id}
              name={this.props.id}
              value={this.props.value}
              onChange={(e) => this.props.onChange(e.target.value)}
              onKeyDown={(e) => {
                if (this.props.onKeyDown) {
                  this.props.onKeyDown(e);
                }
              }}
              onClick={this.props.onClick}
              placeholder={this.props.placeholder}
              tabIndex={this.props.tabIndex}
              disabled={this.props.disabled}
              maxLength={this.props.maxLength}
            />
            <div
              className="font-11"
              style={{
                color: '#6c6d70',
                marginBottom: '1rem',
              }}>{`${this.props.value.length} / ${this.props.maxLength} characters`}</div>
          </>
        ) : (
          <input
            ref={(input) => {
              this.textInputRef = input;
            }}
            type={this.props.type}
            className={this.props.transparent ? 'text-input transparent' : 'text-input'}
            id={this.props.id}
            name={this.props.id}
            value={this.props.value}
            onChange={(e) => this.props.onChange(e.target.value)}
            onKeyDown={(e) => {
              if (this.props.onKeyDown) {
                this.props.onKeyDown(e);
              }
            }}
            onClick={this.props.onClick}
            placeholder={this.props.placeholder}
            tabIndex={this.props.tabIndex}
            disabled={this.props.disabled}
            maxLength={this.props.maxLength}
            onInput={this.props.onInput}
            min={this.props.min}
            max={this.props.max}
          />
        )}
      </label>
    );
  }
}

Text.propTypes = {
  label: PropTypes.node,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  textarea: PropTypes.bool,
  type: PropTypes.string,
  maxLength: PropTypes.number,
};

Text.defaultProps = {
  label: null,
  id: null,
  onChange: null,
  placeholder: null,
  value: '',
  textarea: false,
  type: 'text',
};

export default Text;
