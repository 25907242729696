import { useMemo } from 'react';
import { MinusCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Selector } from 'components/pages/live-search/V2/selector';
import { singleValues } from './utils-options';
import { statusColor } from 'constants/status-color';
import styles from './filter-value.module.scss';
import { Button } from 'components/pages/communication-hub/components';
import classNames from 'classnames';

export const FilterValue = ({
  filter,
  fields,
  idx,
  options,
  handleFilter,
  handleValues,
  removeFilter,
  onSelectAll,
  availableFields,
}) => {
  const isStatusField = useMemo(() => filter.field === 'status', []);

  const isMultiSelect = useMemo(() => !singleValues.includes(filter.field), [filter]);

  const getPlaceholderSelection = () => {
    if (!isMultiSelect) {
      return (
        <BooleanChip boolean={filter.values}>{options.find((option) => option.id === filter.values)?.name}</BooleanChip>
      );
    }

    if (filter.values?.length === 0) {
      return 'Select your options';
    }

    return (
      <span className={styles.selectionWrapper}>
        {filter.values?.map((value) => {
          const name = options?.find((option) => option.id === value)?.name;
          if (name) {
            return (
              <ChipItem
                key={value}
                onClick={() => handleValues(idx, { value })}
                style={isStatusField ? { background: statusColor[value], color: '#fff' } : {}}>
                {name}
              </ChipItem>
            );
          }
        })}
      </span>
    );
  };

  return (
    <>
      {isStatusField && <div className={styles.stage}>Stage</div>}
      <div
        className={
          isStatusField ? classNames(styles.filterWrapperIsStatusFields, styles.filterWrapper) : styles.filterWrapper
        }>
        <div className={isStatusField ? classNames(styles.filterIsStatusField, styles.filter) : styles.filter}>
          {!isStatusField && (
            <Selector
              id="fields"
              placeholder={fields.find((item) => filter.field === item.id)?.name || 'Select a field'}
              options={availableFields}
              currentSelected={filter?.id}
              onHandleChange={(value) => handleFilter(idx, { value: value.id })}
              className="selector-fields"
            />
          )}
          {filter.field && (
            <Selector
              id="values"
              placeholder={getPlaceholderSelection()}
              options={options || []}
              onHandleChange={(value) => {
                handleValues(idx, { value: value.id });
              }}
              currentSelected={filter.values}
              multiSelect={isMultiSelect}
              onSelectAll={onSelectAll}
              className="selector-values"
            />
          )}
        </div>
        {!isStatusField ? (
          <Button type="button" onClick={removeFilter} className={styles.filterWrapper_button}>
            <MinusCircleOutlined /> Remove Filter
          </Button>
        ) : (
          <span />
        )}
      </div>
    </>
  );
};

const BooleanChip = ({ children, boolean }) => {
  return <ChipItem className={styles[boolean]}>{children}</ChipItem>;
};

const ChipItem = ({ children, className, onClick, ...props }) => {
  return (
    <span className={`${styles.item} ${className || ''}`} {...props}>
      {children}{' '}
      <button onClick={onClick}>
        <CloseCircleOutlined />
      </button>
    </span>
  );
};
