import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class Validation extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    messages: PropTypes.array,
    prefix: PropTypes.string,
  };

  static defaultProps = {
    messages: [],
    prefix: '',
  };

  renderValidationMessages(messages) {
    if (messages && messages.length) {
      const { prefix } = this.props;
      return messages.map((message, i) => (
        <div className="validation-message" key={prefix + i + message}>
          {message}
        </div>
      ));
    }

    return null;
  }

  render() {
    const { className, children, messages } = this.props;

    const wrapperClasses = {
      'validation-message__wrapper': true,
      [`${className}`]: className,
      'validation-message__wrapper--invalid': this.props.messages && this.props.messages.length,
    };

    return (
      <div className={classNames(wrapperClasses)}>
        {children}
        {this.renderValidationMessages(messages)}
      </div>
    );
  }
}

export default Validation;
