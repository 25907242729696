import { store } from '../store';
import { _getMarketingCampaign } from 'controllers/schools/schools_controller';

export const OPEN_CAMPAIGN_MODAL = 'OPEN_CAMPAIGN_MODAL';
export const CLOSE_CAMPAIGN_MODAL = 'CLOSE_CAMPAIGN_MODAL';
export const OPEN_SAVE_MODAL = 'OPEN_SAVE_MODAL';
export const CLOSE_SAVE_MODAL = 'CLOSE_SAVE_MODAL';

// campaign calls
export const GET_CAMPAIGN_REQUEST = 'GET_CAMPAIGN_REQUEST';
export const GET_CAMPAIGN_SUCCESS = 'GET_CAMPAIGN_SUCCESS';
export const GET_CAMPAIGN_FAILURE = 'GET_CAMPAIGN_FAILURE';
export const UPDATE_CAMPAIGN_VALUE = 'UPDATE_CAMPAIGN_VALUE';
export const SET_CAMPAIGN_PARAM = 'SET_CAMPAIGN_PARAM';
export const SET_LEAD_COUNT = 'SET_LEAD_COUNT';

export const PARSE_PARAMS = ['nodes', 'edges', 'viewport', 'isRecurringAdvanced'];

// actions
export const onOpenEditModal = (nodeId) => store.dispatch({ type: OPEN_CAMPAIGN_MODAL, payload: nodeId });
export const onCloseEditModal = () => store.dispatch({ type: CLOSE_CAMPAIGN_MODAL });
export const onOpenSaveModal = () => store.dispatch({ type: OPEN_SAVE_MODAL });
export const onCloseSaveModal = () => store.dispatch({ type: CLOSE_SAVE_MODAL });

// actions campaign
export const getCampaign = (schoolId, campaignId) => async (dispatch) => {
  dispatch({ type: GET_CAMPAIGN_REQUEST });

  try {
    const response = await _getMarketingCampaign(schoolId, campaignId);

    const parsedParams = response.params.map((param) => {
      let value;

      if (PARSE_PARAMS.includes(param.key)) {
        value = JSON.parse(param.value);
      } else {
        value = param.value;
      }

      return {
        ...param,
        value,
      };
    });

    dispatch({ type: GET_CAMPAIGN_SUCCESS, payload: { ...response, params: parsedParams } });
  } catch (error) {
    dispatch({ type: GET_CAMPAIGN_FAILURE, error });
  }
};

export const updateCampaignValue = ({ key, value }) => ({
  type: UPDATE_CAMPAIGN_VALUE,
  payload: { key, value },
});

export const setCampaignParam = ({ key, value }) => ({
  type: SET_CAMPAIGN_PARAM,
  payload: { key, value },
});

export const setLeadCount = (count) => ({
  type: SET_LEAD_COUNT,
  payload: count,
});

const initialState = {
  isModalOpen: false,
  isModalSaveOpen: false,
  nodeId: null,
  // campaign
  campaign: {},
  leadCount: 0,
  loading: false,
  error: null,
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CAMPAIGN_MODAL:
      return {
        ...state,
        isModalOpen: true,
        nodeId: action.payload,
      };
    case CLOSE_CAMPAIGN_MODAL:
      return {
        ...state,
        isModalOpen: false,
        nodeId: null,
      };

    case OPEN_SAVE_MODAL:
      return {
        ...state,
        isModalSaveOpen: true,
      };
    case CLOSE_SAVE_MODAL:
      return {
        ...state,
        isModalSaveOpen: false,
      };

    case GET_CAMPAIGN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        campaign: action.payload,
      };
    case GET_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UPDATE_CAMPAIGN_VALUE:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          [action.payload.key]: action.payload.value,
        },
      };
    case SET_CAMPAIGN_PARAM:
      const params = [...state.campaign.params];
      const paramIndex = params.findIndex((param) => param.key === action.payload.key);

      if (paramIndex >= 0) {
        params[paramIndex] = { key: action.payload.key, value: action.payload.value };
      } else {
        params.push({ key: action.payload.key, value: action.payload.value });
      }

      return {
        ...state,
        campaign: {
          ...state.campaign,
          params,
        },
      };

    case SET_LEAD_COUNT:
      return {
        ...state,
        leadCount: action.payload,
      };

    default:
      return state;
  }
};

export default campaignReducer;
