import { _addMarketingCampaign, _updateMarketingCampaignStatus } from 'controllers/schools/schools_controller';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaign, updateCampaignValue, setCampaignParam, PARSE_PARAMS } from 'redux/campaigns/campaigns-reducer';

export const useCampaign = () => {
  const dispatch = useDispatch();
  const campaign = useSelector((state) => state.campaigns.campaign);
  const loading = useSelector((state) => state.campaigns.loading);
  const error = useSelector((state) => state.campaigns.error);

  const initCampaign = (schoolId, campaignId) => dispatch(getCampaign(schoolId, campaignId));

  const updateValue = ({ key, value }) => {
    dispatch(updateCampaignValue({ key, value }));
  };

  const setParam = ({ key, value }) => {
    dispatch(setCampaignParam({ key, value }));
  };

  const getParamValue = (key) => {
    const param = campaign.params?.find((param) => param.key === key);
    if (param) return param.value;
  };

  const updateCampaign = async (schoolId) => {
    const campaignToUpdate = { ...campaign };
    delete campaignToUpdate.created_at;
    delete campaignToUpdate.updated_at;
    delete campaignToUpdate.status;

    campaignToUpdate.params = campaignToUpdate.params.map((item) => {
      if (PARSE_PARAMS.includes(item.key)) {
        return {
          key: item.key,
          value: JSON.stringify(item.value),
        };
      }

      return item;
    });

    try {
      let update = await _addMarketingCampaign(schoolId, campaignToUpdate);
      console.log('update', update);
    } catch (error) {
      console.log('updateCampaign', error);
    }
  };

  const setCampaignStatus = async (schoolId, campaignId, status) => {
    try {
      let updateStatus = await _updateMarketingCampaignStatus(schoolId, campaignId, status);
    } catch (error) {
      console.log('setCampaignStatus', error);
    }
  };

  return {
    initCampaign,
    campaign,
    loading,
    error,
    updateCampaignValue: updateValue,
    getParamValue,
    setCampaignParam: setParam,
    setCampaignStatus,
    updateCampaign,
  };
};
