import React, { Component, Fragment } from 'react';
import Dropdown from 'components/form/dropdown/Dropdown';
import moment from 'moment';
import InputText from 'components/form/text/Text';
import days from 'util/days';
import months from 'util/months';
import timeZones from 'util/time-zones';
import campaingConstants from '../../utils/CampaingConstants';
import styles from './ScheduleForm.module.scss';
import classNames from 'classnames';

export default class ScheduleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recurrence: campaingConstants.RECURRENCE_WEEKLY_VALUE,
      weekly_value_days: days,
      start_date: moment.utc().format(campaingConstants.FORMAT_DATE),
      range_recurrence: moment.utc().format(campaingConstants.FORMAT_DATE),
      start_time: campaingConstants.DEFAULT_START_TIME,
      range_recurrence_opt: campaingConstants.RANGE_RECURRENCE_OPT_NO_END_DATE_VALUE,
      selectTimeZone: campaingConstants.DEFAULT_TIMEZONE,
    };
  }

  componentDidMount() {
    if (this.props.values) {
      this.initData(this.props.values);
    }
  }

  async initData(values) {
    let checkEveryDay;
    let startDate;
    let startTime;
    let rangeRecurrence;
    let selectTimeZone;
    let localDateTime;

    if (this.props.isRegularMass === true) {
      localDateTime = this.getDateTimeFromUtcToLocal(values.start_date, values.start_time, values.selectTimeZone);

      startDate = localDateTime.date;
      startTime = localDateTime.time;
      selectTimeZone = localDateTime.timeZone;
    } else {
      checkEveryDay = days.every((d) => values.weekly_value_days?.includes(d));
      localDateTime = this.getDateTimeFromUtcToLocal(values.range_recurrence, values.start_time, values.selectTimeZone);
      rangeRecurrence = localDateTime.date;
      startTime = localDateTime.time;
      selectTimeZone = localDateTime.timeZone;
    }

    this.setState({
      ...values,
      checkEveryDay,
      start_date: startDate,
      start_time: startTime,
      range_recurrence: rangeRecurrence,
      selectTimeZone,
    });
  }

  getDateTimeFromUtcToLocal = (date, time, timeZone) => {
    let isValidDate = true;
    timeZone = this.getTimeZone(timeZone);
    let offsetMinutes = this.getOffsetMinutes(timeZone);
    if (!time) {
      time = campaingConstants.DEFAULT_START_TIME;
    }
    if (moment.utc(time, campaingConstants.FORMAT_TIME, true).isValid() === false) {
      time = campaingConstants.DEFAULT_START_TIME;
    }
    if (moment.utc(date, campaingConstants.FORMAT_DATE, true).isValid() === false) {
      if (moment.utc(date).isValid() === true) {
        date = moment.utc(date).format(campaingConstants.FORMAT_DATE);
      } else {
        isValidDate = false;
        date = moment.utc().format(campaingConstants.FORMAT_DATE);
      }
    }

    let dateTime = moment.utc(`${date} ${time}`, `${campaingConstants.FORMAT_DATE} ${campaingConstants.FORMAT_TIME}`);
    if (offsetMinutes !== 0) {
      dateTime = dateTime.add(offsetMinutes, 'minutes');
    }

    return {
      date: isValidDate === true ? dateTime.format(`${campaingConstants.FORMAT_DATE}`) : '',
      time: dateTime.format(`${campaingConstants.FORMAT_TIME}`),
      timeZone,
    };
  };

  getTimeFromLocalToUtc = (date, time, timeZone) => {
    timeZone = this.getTimeZone(timeZone);
    let isValidDate = true;
    let offsetMinutes = this.getOffsetMinutes(timeZone);
    if (!time) {
      time = campaingConstants.DEFAULT_START_TIME;
    }
    if (moment.utc(time, campaingConstants.FORMAT_TIME, true).isValid() === false) {
      time = campaingConstants.DEFAULT_START_TIME;
    }
    if (moment.utc(date, campaingConstants.FORMAT_DATE, true).isValid() === false) {
      if (moment.utc(date).isValid() === true) {
        date = moment.utc(date).format(campaingConstants.FORMAT_DATE);
      } else {
        isValidDate = false;
        date = moment.utc().format(campaingConstants.FORMAT_DATE);
      }
    }

    let dateTime = moment.utc(`${date} ${time}`, `${campaingConstants.FORMAT_DATE} ${campaingConstants.FORMAT_TIME}`);
    if (offsetMinutes !== 0) {
      offsetMinutes *= -1;
      dateTime = dateTime.add(offsetMinutes, 'minutes');
    }

    return {
      date: isValidDate === true ? dateTime.utc().format(`${campaingConstants.FORMAT_DATE}`) : '',
      time: dateTime.utc().format(`${campaingConstants.FORMAT_TIME}`),
      timeZone,
    };
  };

  getOffsetMinutes(timeZone) {
    timeZone = this.getTimeZone(timeZone);
    let utcSignal = timeZone.substring(4, 5);
    let hours = timeZone.substring(5, 10);
    let minutes = moment.duration(hours).asMinutes();

    if (minutes > 0) {
      if (utcSignal === '-') {
        minutes *= -1;
      }
    }
    return minutes;
  }

  getTimeZone(selectTimeZone) {
    if (selectTimeZone && selectTimeZone != '') {
      var timeZone = timeZones.find((x) => x === selectTimeZone);
      if (timeZone) {
        return timeZone;
      }
    }
    return campaingConstants.DEFAULT_TIMEZONE;
  }

  componentWillReceiveProps(nProps) {
    if (nProps.values) {
      this.initData(nProps.values);
    }
  }

  onChange = () => {
    let {
      recurrence,
      range_recurrence: rangeRecurrence,
      start_date: startDate,
      start_time: startTime,
      range_recurrence_opt: rangeRecurrenceOpt,
      range_recurrence_opt_end_by: rangeRecurrenceOptEndBy,
      selectTimeZone,
    } = this.state;
    let utcDateTime;
    if (this.props.isRegularMass === true) {
      utcDateTime = this.getTimeFromLocalToUtc(startDate, startTime, selectTimeZone);
      startDate = utcDateTime.date;
      startTime = utcDateTime.time;
      selectTimeZone = utcDateTime.timeZone;
    } else {
      utcDateTime = this.getTimeFromLocalToUtc(rangeRecurrence, startTime, selectTimeZone);
      rangeRecurrence = utcDateTime.date;
      startTime = utcDateTime.time;
      selectTimeZone = utcDateTime.timeZone;
    }

    let payload = {
      recurrence,
      range_recurrence: rangeRecurrence,
      start_date: startDate,
      start_time: startTime,
      range_recurrence_opt: rangeRecurrenceOpt,
      range_recurrence_opt_end_by: rangeRecurrenceOptEndBy,
      selectTimeZone,
    };

    if (this.props.isRegularMass === false) {
      // recurrence
      switch (recurrence) {
        case 'weekly':
          const { weekly_value_days: weeklyValueDays } = this.state;
          payload = { ...payload, weekly_value_days: weeklyValueDays };
          break;
        case 'monthly':
          const { monthly_day: monthlyDay } = this.state;
          payload = { ...payload, monthly_day: monthlyDay };
          break;
        case 'yearly':
          const { yearly_month: yearlyMonth, yearly_month_day: yearlyMonthDay } = this.state;
          payload = {
            ...payload,
            yearly_month: yearlyMonth,
            yearly_month_day: yearlyMonthDay,
          };
          break;
      }
    }

    this.props.onChange && this.props.onChange(payload);
  };

  getAdditionalOptions = () => {
    const { recurrence } = this.state;
    switch (recurrence) {
      case 'weekly':
        return (
          <>
            <div className="d-flex flex-wrap">
              <div className="m-2 d-flex">
                <input
                  type="checkbox"
                  id="Everyday-"
                  onChange={() => {
                    let { checkEveryDay } = this.state;
                    checkEveryDay = !checkEveryDay;
                    this.setState({ checkEveryDay }, () => {
                      const { checkEveryDay } = this.state;
                      let weeklyValueDays = [];
                      if (checkEveryDay) {
                        weeklyValueDays = JSON.parse(JSON.stringify(days));
                      }
                      this.setState({ weekly_value_days: weeklyValueDays }, () => this.onChange());
                    });
                  }}
                  checked={this.state.checkEveryDay}
                  className="schola-inputs"
                />
                <label className={classNames(styles.label, 'every_weekday font-14 ml-2')} htmlFor="Everyday-">
                  Everyday
                </label>
              </div>

              {days.map((d, i) => {
                return (
                  <div className="m-2 d-flex" key={`${d}-${i}`}>
                    <input
                      type="checkbox"
                      id={`${d}-${i}`}
                      onChange={() => {
                        let { weekly_value_days: weeklyValueDays } = this.state;
                        if (!weeklyValueDays) weeklyValueDays = [];
                        const idx = weeklyValueDays?.indexOf(d);

                        if (idx > -1) weeklyValueDays.splice(idx, 1);
                        else weeklyValueDays.push(d);

                        let checkEveryDay = days.every((d) => weeklyValueDays.includes(d));

                        this.setState({ weekly_value_days: weeklyValueDays, checkEveryDay }, () => this.onChange());
                      }}
                      checked={this.state.weekly_value_days?.indexOf(d) > -1}
                      className="schola-inputs"
                    />
                    <label className={classNames(styles.label, 'every_weekday font-14 ml-2')} htmlFor={`${d}-${i}`}>
                      {d}
                    </label>
                  </div>
                );
              })}
            </div>
          </>
        );
        break;
      case 'monthly':
        return (
          <>
            <div>
              <div className="row form-check d-flex align-items-center font-14">
                <label className={styles.label} htmlFor="monthly-radio-day">
                  Day
                </label>
                <input
                  className="form-input col-2 ml-2 mr-2 schola-inputs"
                  type="number"
                  onChange={(e) => this.setState({ monthly_day: e.target.value }, () => this.onChange())}
                  value={this.state.monthly_day}
                />
                <span>of every month(s)</span>
              </div>
            </div>
          </>
        );
        break;
      case 'yearly':
        return (
          <>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '30px 200px 50px',
                gridGap: '.5rem',
                alignItems: 'center',
              }}>
              <label className={`${styles.label} font-14`} htmlFor="yearly-radio-On">
                On:
              </label>
              <Dropdown
                id="yearly_month"
                className="schola-inputs mb-0"
                options={months}
                onChange={(yearlyMonth) => this.setState({ yearly_month: yearlyMonth }, () => this.onChange())}
              />
              <input
                className="form-input schola-inputs"
                type="number"
                style={{ border: '1px solid #ccc' }}
                onChange={(e) => this.setState({ yearly_month_day: e.target.value }, () => this.onChange())}
                value={this.state.yearly_month_day}
              />
            </div>
          </>
        );
        break;
    }
  };

  onChangeTimeZone = (value) => {
    this.setState({ selectTimeZone: value }, () => this.onChange());
  };

  getTimeOptions = () => {
    let times = ['12:00 AM', '12:30 AM'];
    for (var i = 1; i < 12; i++) {
      times.push(`${`0${i}`.slice(-2)}:00 AM`);
      times.push(`${`0${i}`.slice(-2)}:30 AM`);
    }
    times.push('12:00 PM');
    times.push('12:30 PM');
    for (var i = 1; i < 12; i++) {
      times.push(`${`0${i}`.slice(-2)}:00 PM`);
      times.push(`${`0${i}`.slice(-2)}:30 PM`);
    }
    return times;
  };

  renderRecurrence = () => {
    return (
      <>
        <div className="col-12 schedule-times">
          <h6 className={styles.heading}>Schedule Time</h6>
          <div className={styles.container}>
            <label className="col-1 p-0 font-14 font-bold">Start:</label>
            <div
              style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 3fr', gridGap: '.5rem', alignItems: 'center' }}>
              <InputText
                onChange={(value) => this.setState({ range_recurrence: value }, () => this.onChange())}
                value={moment.utc(this.state?.range_recurrence).format('YYYY-MM-DD')}
                type="date"
              />
              <Dropdown
                className="schola-inputs"
                id="start_time"
                options={this.getTimeOptions()}
                value={this.state.start_time}
                onChange={(startTime) => this.setState({ start_time: startTime }, () => this.onChange())}
              />
              <Dropdown
                className="schola-inputs"
                id="time_zone"
                options={timeZones}
                value={
                  this.state.selectTimeZone !== ''
                    ? timeZones.find((x) => x === this.state.selectTimeZone)
                    : campaingConstants.DEFAULT_TIMEZONE
                }
                onChange={(e) => this.onChangeTimeZone(e)}
              />
            </div>
          </div>
          <div className={styles.container}>
            <h6 className={styles.heading}>Recurrence</h6>
            <div className="row recurrence">
              <div className="col-2 align-items-center" style={{ borderRight: '1px solid #ccc' }}>
                <div className="form-check d-flex align-items-center mt-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="recurrence_day"
                    onChange={() =>
                      this.state.recurrence != 'weekly' &&
                      this.setState({ recurrence: 'weekly' }, () => this.onChange())
                    }
                    checked={this.state.recurrence == 'weekly'}
                    id="radio_weekly"
                  />
                  <label className={classNames(styles.label, 'ml-2 font-14')} htmlFor="radio_weekly">
                    Weekly
                  </label>
                </div>
                <div className="form-check d-flex align-items-center mt-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="recurrence_day"
                    onChange={() =>
                      this.state.recurrence != 'monthly' &&
                      this.setState({ recurrence: 'monthly', monthly_op: 'day' }, () => this.onChange())
                    }
                    checked={this.state.recurrence == 'monthly'}
                    id="radio_monthly"
                  />
                  <label className={classNames(styles.label, 'ml-2 font-14')} htmlFor="radio_monthly">
                    Monthly
                  </label>
                </div>
                <div className="form-check d-flex align-items-center mt-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="recurrence_day"
                    onChange={() =>
                      this.state.recurrence != 'yearly' &&
                      this.setState({ recurrence: 'yearly', yearly_opt: 'on' }, () => this.onChange())
                    }
                    checked={this.state.recurrence == 'yearly'}
                    id="radio_yearly"
                  />
                  <label className={classNames(styles.label, 'ml-2 font-14')} htmlFor="radio_yearly">
                    Yearly
                  </label>
                </div>
              </div>
              <div className="col-10 d-flex align-items-center">{this.getAdditionalOptions()}</div>
            </div>
          </div>
          <div className={styles.container}>
            <h6 className={styles.heading}>Range of recurrence</h6>
            <div className="d-flex">
              <div className="py-3 px-0 col-7">
                <div className="form-check d-flex align-items-center mb-3">
                  <input
                    className="form-check-input schola-inputs"
                    type="radio"
                    name="range_recurrence_opt"
                    id="end-by"
                    onChange={() =>
                      this.state.range_recurrence_opt != 'end_by' &&
                      this.setState({ range_recurrence_opt: 'end_by' }, () => this.onChange())
                    }
                    checked={this.state.range_recurrence_opt == 'end_by'}
                  />
                  <label className={classNames(styles.label, 'font-14 mx-2')} htmlFor="end-by">
                    End by:
                  </label>
                  <InputText
                    className="col-6"
                    onChange={(value) => this.setState({ range_recurrence_opt_end_by: value }, () => this.onChange())}
                    value={this.state?.range_recurrence_opt_end_by}
                    min={moment().format('YYYY-MM-DD')}
                    type="date"
                  />
                </div>
                <div className="form-check d-flex align-items-center mb-3">
                  <input
                    className="form-check-input schola-inputs"
                    id="no_end_date"
                    type="radio"
                    name="range_recurrence_opt"
                    onChange={() =>
                      this.state.range_recurrence_opt != 'no_end_date' &&
                      this.setState({ range_recurrence_opt: 'no_end_date' }, () => this.onChange())
                    }
                    checked={this.state.range_recurrence_opt == 'no_end_date'}
                  />
                  <label className={classNames(styles.label, 'mx-2 font-14')} htmlFor="no_end_date">
                    No end date
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  renderRegularMass = () => {
    return (
      <>
        <div className="col-12 schedule-times px-0">
          <h6 className={styles.heading}>Schedule Time</h6>
          <div className="my-3">
            <div className="row">
              <label className="col-6 font-14 font-bold">Date Time:</label>
            </div>
            <div
              style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 3fr', gridGap: '.5rem', alignItems: 'center' }}>
              <InputText
                className="schola-inputs"
                onChange={(value) => this.setState({ start_date: value }, () => this.onChange())}
                value={moment.utc(this.state?.start_date).format('YYYY-MM-DD')}
                type="date"
              />
              <Dropdown
                className="schola-inputs"
                id="start_time"
                options={this.getTimeOptions()}
                value={this.state.start_time}
                onChange={(startTime) => this.setState({ start_time: startTime }, () => this.onChange())}
              />
              <Dropdown
                className="schola-inputs"
                id="time_zone"
                options={timeZones}
                value={
                  this.state.selectTimeZone !== ''
                    ? timeZones.find((x) => x === this.state.selectTimeZone)
                    : campaingConstants.DEFAULT_TIMEZONE
                }
                onChange={(e) => this.onChangeTimeZone(e)}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const { disabled, isRegularMass, values } = this.props;
    if (isRegularMass === true) {
      return this.renderRegularMass();
    }

    if (disabled) {
      return <></>;
    }
    return this.renderRecurrence();
  }
}
