import { useReactFlow } from '@xyflow/react';

export const useUpdateNode = () => {
  const { setNodes } = useReactFlow();

  const onChangeNode = (nodeId, key, value) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          // it's important to create a new node object
          return {
            ...node,
            data: {
              ...node.data,
              [key]: value,
            },
          };
        }

        return node;
      })
    );
  };

  const handleNodeParam = ({ nodeId, key, value }) => {
    setNodes((nde) =>
      nde.map((node) => {
        if (node.id === nodeId) {
          // Find the item in the data array that matches the key
          let keyFound = false;
          const updatedData = node.data.map((item) => {
            if (item.key === key) {
              keyFound = true;
              return { ...item, value };
            }
            return item;
          });

          // If the key was not found, add a new { key, value } object to the data array
          if (!keyFound) {
            updatedData.push({ key, value });
          }

          return {
            ...node,
            data: updatedData,
          };
        }
        return node;
      })
    );
  };

  const removeNodeParam = ({ nodeId, key }) => {
    setNodes((nde) =>
      nde.map((node) => {
        if (node.id === nodeId) {
          // Filter out the item in the data array that matches the key
          const updatedData = node.data.filter((item) => item.key !== key);

          return {
            ...node,
            data: updatedData,
          };
        }
        return node;
      })
    );
  };

  const updateNodeData = (nodeId, newData) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: newData,
          };
        }
        return node;
      })
    );
  };

  return { onChangeNode, handleNodeParam, removeNodeParam, updateNodeData };
};
