import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const thumb = {
  borderRadius: 2,
  marginBottom: 8,
  width: '100%',
  padding: 4,
};

const thumbInner = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  minWidth: 0,
};

const img = {
  display: 'block',
  width: 150,
  height: '100%',
};

const iconContainer = {
  display: 'flex',
  flexDirection: 'column',
};

const PreviewImage = (props) => {
  // const getUrl = url => props.getUrlImage(url)

  return (
    <div style={thumb}>
      <div style={thumbInner}>
        <img
          ref={props.previewRef}
          // src={getUrl(props.file.filename)}
          src={props.file.filename}
          style={img}
        />
        <div style={iconContainer}>
          {!props.hideEdit && (
            <i
              data-testid="icon-edit"
              className="material-icons md-light image-delete icon-edit-delete"
              onClick={() => props.editCropImage()}>
              edit
            </i>
          )}
          {props.isDeleted && (
            <i
              data-testid="icon-delete"
              className="material-icons md-light image-delete icon-edit-delete"
              onClick={() => props.deleteImage()}>
              delete
            </i>
          )}
        </div>
      </div>
    </div>
  );
};

PreviewImage.propTypes = {
  file: PropTypes.object,
};

PreviewImage.defaultProps = {
  file: { name: '', preview: '' },
};

export default PreviewImage;
