import { useState } from 'react';
import { Handle, Position, useReactFlow } from '@xyflow/react';
import { Flex, Typography, Button, Modal } from 'antd';
import { CopyOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import styles from '../flow-designer.module.scss';
import { onOpenEditModal } from 'redux/campaigns/campaigns-reducer';

export const NodeWrapper = ({ children, isFirstNode }) => {
  return (
    <div className={styles.nodeWrapper}>
      {!isFirstNode && <Handle type="target" position={Position.Top} className={styles.handle} />}
      {children}
      <Handle type="source" position={Position.Bottom} className={styles.handle} />
    </div>
  );
};

const NodeHeader = ({ id, Icon, children, deactiveEdit, hideActions }) => {
  const { deleteElements, setNodes, getNode } = useReactFlow();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleEdit = (id) => {
    onOpenEditModal(id);
  };

  const handleDeleteNode = (id) => {
    deleteElements({ nodes: [{ id }] });
    setIsModalVisible(false);
  };

  const handleDuplicate = (id) => {
    const currentNode = getNode(id);
    if (currentNode) {
      const newNode = {
        ...currentNode,
        id: `${currentNode.type}-${Date.now()}`,
        position: {
          x: currentNode.position.x + 120,
          y: currentNode.position.y + 120,
        },
      };
      setNodes((nodes) => [...nodes, newNode]);
    }
  };

  const showDeleteModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Flex justify="space-between" align="center" className={styles.header}>
        <Flex align="center" gap="small">
          <Icon style={{ color: '#0099cc' }} />
          <Label>{children}</Label>
        </Flex>
        {!hideActions && (
          <Flex align="center">
            <Button onClick={() => handleDuplicate(id)} type="text" icon={<CopyOutlined />} />
            {!deactiveEdit && <Button onClick={() => handleEdit(id)} type="text" icon={<EditOutlined />} />}
            <Button onClick={showDeleteModal} type="text" icon={<DeleteOutlined />} />
          </Flex>
        )}
      </Flex>
      <Modal
        title="Confirm Deletion"
        open={isModalVisible}
        onOk={() => handleDeleteNode(id)}
        onCancel={handleCancel}
        okText="Delete"
        cancelText="Cancel">
        <p>Are you sure you want to delete this node?</p>
      </Modal>
    </>
  );
};

const Label = ({ children }) => {
  return (
    <Typography.Text strong style={{ fontSize: '1rem' }}>
      {children}
    </Typography.Text>
  );
};

NodeWrapper.Header = NodeHeader;
NodeWrapper.Label = Label;
