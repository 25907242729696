import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNodesState, useEdgesState, addEdge, useReactFlow } from '@xyflow/react';
import { useNodePlacement } from '.';
import { useSelector } from 'react-redux';
import { useTemplates } from 'components/pages/communication-hub/hooks/useCampaignTemplates';
import { defineDefaultParamsBySchool } from 'components/pages/communication-hub/components';

export const useFlowDesigner = (campaign) => {
  const { currentSchool } = useSelector((state) => state.school);
  const templates = useTemplates();
  const defaultNodeParams = defineDefaultParamsBySchool(currentSchool, templates[0]);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [rfInstance, setRfInstance] = useState(null);

  const { fitView, setViewport } = useReactFlow();

  const onSave = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject();

      return Object.entries(flow).map(([key, value]) => ({
        key,
        value,
      }));
    }
  }, [rfInstance]);

  const getFlowElements = (key) => {
    const item = campaign.params?.find((item) => item.key === key);
    if (item?.value) {
      return item.value;
    }
  };

  // simulate async node map
  useEffect(() => {
    if (campaign) {
      const nodes = getFlowElements('nodes');
      const edges = getFlowElements('edges');
      const viewport = getFlowElements('viewport') || {};

      if (nodes?.length > 0) {
        restoreFlow({ nodes, edges, viewport });
      }
    }
  }, [campaign, setNodes, setEdges, setViewport]);

  const restoreFlow = async ({ nodes, edges, viewport }) => {
    const lastNode = nodes.at(-1);

    const lastPosition = lastNode.position;

    const placeholderNode = {
      id: 'placeholder',
      type: 'placeholderNode',
      position: { ...lastPosition, y: lastPosition.y * 3 },
      data: { nodeSelection: 'send-email' },
    };

    const placeholderEdge = { id: `${lastNode.id}->placeholder`, source: lastNode.id, target: 'placeholder' };

    // Provide default values
    const { x = 0, y = 0, zoom = 1 } = viewport;
    setNodes([...nodes, placeholderNode] || []);
    setEdges([placeholderEdge, ...edges] || []);
    setViewport({ x, y, zoom });
  };

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const { calculateNodePosition } = useNodePlacement();

  const createNode = (type, event) => {
    const position = calculateNodePosition(event);

    const isFirstNode = nodes.length === 0;

    let newNodeData;
    switch (type) {
      case 'emailNode':
        newNodeData = defaultNodeParams;
        break;
      case 'smsNode':
        newNodeData = defaultNodeParams;
        break;
      case 'timerNode':
        newNodeData = {
          type: 'timerNode',
          time: 8,
          timeframe: 'hours',
        };
        break;
      case 'conditionNode':
        newNodeData = {
          type: 'conditionNode',
          userAction: 'open-last-message',
          condition: 'true',
          timeframe: 24,
        };
        break;
      default:
        return;
    }

    const newNode = {
      id: isFirstNode ? 'start' : `${type}-${nodes.length + 1}`,
      type,
      position,
      data: newNodeData,
    };

    setNodes((nds) => {
      const newNodes = [...nds, newNode];
      // Fit view after adding the new node
      if (newNodes.length > 3) {
        setTimeout(() => {
          fitView({
            padding: 0.2,
            duration: 800,
          });
        }, 50);
      }
      return newNodes;
    });
  };

  const onNodeClick = useCallback(
    (type) => {
      createNode(type, null);
    },
    [createNode]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const type = event.dataTransfer.getData('application/reactflow');
      if (!type) return;
      createNode(type, event);
    },
    [createNode]
  );

  const isEmptyNode = useMemo(() => {
    const isEmpty = nodes.length === 0;
    return isEmpty;
  }, [nodes]);

  return {
    onNodesChange,
    onEdgesChange,
    setRfInstance,
    nodes,
    edges,
    onConnect,
    onDragOver,
    onNodeClick,
    onDrop,
    onSave,
    isEmptyNode,
  };
};
