import { ColorPicker, Flex } from 'antd';

export const CampaignColors = ({ layout, onChangeParams, getParamValue }) => {
  const { PRIMARY_COLOR_KEY, SECONDARY_COLOR_KEY } = layout?.keys;

  const handleColorChange = (key, eventColor) => {
    const { metaColor } = eventColor;
    onChangeParams({ key, value: metaColor.toHexString() });
  };

  return (
    <Flex gap="middle">
      <ColorPicker
        value={getParamValue(PRIMARY_COLOR_KEY) || '#006D8F'}
        showText
        onChange={(eventColor) => handleColorChange(PRIMARY_COLOR_KEY, eventColor)}
      />
      <ColorPicker
        value={getParamValue(SECONDARY_COLOR_KEY) || '#FFFFFF'}
        showText
        onChange={(eventColor) => handleColorChange(SECONDARY_COLOR_KEY, eventColor)}
      />
    </Flex>
  );
};
