import { useEffect, useMemo, useState } from 'react';
import { Typography, Row, Col, Flex } from 'antd';
import CampaingConstants from 'components/pages/campaigns/utils/CampaingConstants';
import { InputPersonalization } from 'components/pages/campaigns/components/shared/input-personalization';
import { CTAcampaign } from 'components/pages/campaigns/components/shared/cta-campaign';
import { LAYOUTS } from 'components/pages/campaigns/components/email/Layouts';
import { Signature } from 'components/pages/campaigns/components/shared/signature';
import CDPreview from 'components/pages/campaigns/components/email/CDPreview';
import EmailMainBody from 'components/pages/campaigns/components/email/EmailMainBody';
import { InputCampaign, SectionTitle } from 'components/pages/campaigns/components/shared/input-campaign';
import { useUpdateNode } from '../../hooks';
import { useNodesData } from '@xyflow/react';
import styles from './edit-node-content.module.scss';
import { SwitchPreview } from 'components/pages/campaigns/components/shared/switch-preview';
import { CampaignColors } from 'components/pages/campaigns/components/shared/campaign-colors';
import { SocialMediaLinks } from 'components/pages/campaigns/components/shared/social-media-links/social-media-links';
import EmailSchoolBrand from 'components/pages/campaigns/components/email/EmailSchoolBrand';
import EmailImage from 'components/pages/campaigns/components/email/EmailImage';
import { Button } from 'components/pages/communication-hub/components';
import { onCloseEditModal } from 'redux/campaigns/campaigns-reducer';
import { _sendTestMarketingCampaign } from 'controllers/schools/schools_controller';
import Modal from 'components/modal/Modal';
import ToastNotification from 'components/toast-notification/ToastNotification';
import PhoneInput from 'antd-phone-input';
import { getProfile } from 'util/auth';

export const EditNodeContent = ({ nodeId, campaign, currentSchool }) => {
  const [layout] = LAYOUTS;
  const { SUBJECT_KEY, EMAIL_KEY, NAME_KEY } = CampaingConstants;
  const { HEADER_KEY, CTA_TYPE_KEY } = layout.keys;

  const currentNode = useNodesData(nodeId);
  const isEmail = currentNode.type === 'emailNode';

  const { updateNodeData } = useUpdateNode();
  const [tempData, setTempData] = useState([]);
  const [showSendTest, setShowSendTest] = useState(false);
  const [to, setTo] = useState('');
  const [toast, setToast] = useState(null);

  // initialize node data
  useEffect(() => {
    setTempData(currentNode.data);
  }, [nodeId]);

  const getNodeParamValue = (key) => {
    const value = tempData.find((item) => item.key === key)?.value;
    if (!value) return '';
    return value;
  };

  const onChangeParams = (payload) => {
    setTempData((prevData) => {
      const itemExists = prevData.some((item) => item.key === payload.key);
      if (itemExists) {
        return prevData.map((item) => (item.key === payload.key ? { ...item, value: payload.value } : item));
      }
      return [...prevData, { key: payload.key, value: payload.value }];
    });
  };

  const onRemoveParam = (key) => {
    setTempData((prevData) => prevData.filter((item) => item.key !== key));
  };

  const campaignModified = useMemo(() => {
    return {
      ...campaign,
      params: tempData,
    };
  }, [campaign, tempData]);

  const [view, setView] = useState('edit'); // 'edit' || 'preview'
  const onSwitchView = (key) => {
    setView(key);
  };

  const validateInputs = () => {
    const requiredKeys = [
      { key: SUBJECT_KEY, name: 'Subject' },
      { key: EMAIL_KEY, name: 'From Email' },
      { key: NAME_KEY, name: 'Name' },
      { key: CTA_TYPE_KEY, name: 'Call to Action' },
    ];
    const errors = [];

    requiredKeys.forEach(({ key, name }) => {
      console.log(key, getNodeParamValue(key));
      if (!getNodeParamValue(key) || getNodeParamValue(key) === 'none') {
        errors.push(`${name} is required`);
      }
    });

    return {
      isValid: errors.length === 0,
      errors,
    };
  };

  const [error, setError] = useState('');

  const onSave = () => {
    const validation = validateInputs();

    if (validation.isValid) {
      updateNodeData(nodeId, tempData);
      onCloseEditModal();
    } else {
      setError(validation.errors.join(', '));
    }
  };

  const onSendTest = () => {
    const profile = getProfile();
    if (isEmail) setTo(profile.email || '');
    setShowSendTest(true);
  };

  const renderTest = () => {
    const sendTest = () => {
      let _campaign = JSON.parse(JSON.stringify(campaignModified));
      _campaign.platform = isEmail ? 'email' : 'sms';
      _campaign.type = 'Regular Mass';
      console.log(_campaign);

      let payload = {
        marketingCampaign: _campaign,
        lead: {
          phone: to,
          email: to,
        },
      };

      _sendTestMarketingCampaign(currentSchool.id, payload).then((res) => {
        setShowSendTest(false);
        if (res.error) {
          setToast({
            type: 'error',
            message:
              'Oops! There seems to be a slight problem on our end. Please try again in a few minutes. If this problem continues, please contact our support team to describe the issue.',
          });
        } else if (res.success === false) {
          setToast({
            type: 'error',
            message: `Unsuccessful send, try with another ${isEmail ? 'email' : 'phone number'}`,
          });
        } else {
          setToast({
            type: 'success',
            message: `Sent successfully to '${to}'.`,
          });
        }
      });
    };

    const close = () => {
      setShowSendTest(false);
    };

    return (
      <Modal isVisible={showSendTest} className="sms-layouts-modal" title="Send Test" onClose={close}>
        <div className="content">
          <div className="d-flex flex-wrap" style={{ alignItems: 'center' }}>
            <label className="font-16 font-400">{isEmail ? 'Email' : 'Phone'}:</label>
            {!isEmail && (
              <PhoneInput
                value={to}
                onChange={(e) => {
                  setTo(`+${e.countryCode}${e.areaCode}${e.phoneNumber}`);
                }}
                country="us"
                onlyCountries={['us', 'mx']}
                placeholder="Enter a valid phone number"
                enableArrow
                distinct
              />
            )}
            {isEmail && (
              <input
                type="tel"
                value={to}
                placeholder={`Enter a valid email`}
                onChange={(e) => setTo(e.target.value)}
              />
            )}
          </div>
        </div>
        <div className="modal-footer">
          <Button type="default" onClick={close}>
            Cancel
          </Button>
          <Button disabled={to === ''} onClick={sendTest}>
            Send
          </Button>
        </div>
      </Modal>
    );
  };

  const renderToast = () => {
    return (
      <>
        {toast && (
          <ToastNotification type={toast.type} message={toast.message} time={4000} onClose={() => setToast(null)} />
        )}
      </>
    );
  };

  return (
    <>
      <Row className={styles.layout}>
        <Col span={12} className={styles.details}>
          <Title>{isEmail ? 'Email' : 'SMS'} Details</Title>
          <GroupInputs>
            {isEmail && (
              <>
                <div>
                  <SectionTitle>Subject</SectionTitle>
                  <InputPersonalization
                    field={SUBJECT_KEY}
                    value={getNodeParamValue(SUBJECT_KEY)}
                    onChangeParams={onChangeParams}
                  />
                </div>
                <div>
                  <SectionTitle>From</SectionTitle>
                  <Row gutter={12}>
                    <Col span={12}>
                      <InputCampaign id={EMAIL_KEY} value={getNodeParamValue(EMAIL_KEY)} onChange={onChangeParams} />
                    </Col>
                    <Col span={12}>
                      <InputCampaign id={NAME_KEY} value={getNodeParamValue(NAME_KEY)} onChange={onChangeParams} />
                    </Col>
                  </Row>
                </div>
                <div>
                  <SectionTitle>School Logo</SectionTitle>
                  <EmailSchoolBrand
                    campaign={campaignModified}
                    school={currentSchool}
                    school_id={currentSchool.id}
                    layout={layout}
                    onChangeParams={onChangeParams}
                    onRemoveParam={onRemoveParam}
                  />
                </div>
                <div>
                  <SectionTitle>School Colors</SectionTitle>
                  <CampaignColors layout={layout} onChangeParams={onChangeParams} getParamValue={getNodeParamValue} />
                </div>
                <div>
                  <SectionTitle>Social Media Links</SectionTitle>
                  <SocialMediaLinks layout={layout} onChangeParams={onChangeParams} getParamValue={getNodeParamValue} />
                </div>
              </>
            )}
            {isEmail && (
              <div>
                <SectionTitle>Call to Action</SectionTitle>
                <CTAcampaign layout={layout} getParamValue={getNodeParamValue} onChangeParams={onChangeParams} />
              </div>
            )}

            <div>
              <SectionTitle>Signature</SectionTitle>
              <Signature
                school={{ name: 'Test School' }}
                layout={layout}
                getParamValue={getNodeParamValue}
                onChangeParams={onChangeParams}
              />
            </div>
            <EmailImage
              campaign={campaignModified}
              school_id={currentSchool.id}
              layout={layout}
              onChangeParams={onChangeParams}
              onRemoveParam={onRemoveParam}
            />
          </GroupInputs>
        </Col>

        <Col span={12} className={styles.content}>
          <Flex justify="space-between" align="center">
            <Title>{isEmail ? 'Email' : 'SMS'} Content</Title>
            <SwitchPreview onSwitchView={onSwitchView} />
          </Flex>
          {view === 'edit' && (
            <GroupInputs>
              {isEmail && (
                <div>
                  <SectionTitle>Header</SectionTitle>
                  <InputPersonalization
                    field={HEADER_KEY}
                    value={getNodeParamValue(HEADER_KEY)}
                    onChangeParams={onChangeParams}
                  />
                </div>
              )}

              <EmailMainBody
                campaign={campaignModified}
                platform={isEmail ? 'email' : 'sms'}
                validatePlanInFrontend={false}
                isPremium
                school={currentSchool}
                layout={layout}
                onChangeParams={onChangeParams}
              />
            </GroupInputs>
          )}
          {view === 'preview' && (
            <CDPreview
              school={currentSchool}
              campaign={campaignModified}
              platform={isEmail ? 'email' : 'sms'}
              layout={layout}
            />
          )}
        </Col>
      </Row>
      <div className={styles.actions}>
        {error && (
          <Typography.Paragraph type="danger">
            Please fill in all required fields to save correctly: {error}
          </Typography.Paragraph>
        )}
        <div className={styles.buttons}>
          <Button type="default" onClick={onCloseEditModal}>
            Cancel
          </Button>
          <Button id="test" onClick={onSendTest}>
            Send Test
          </Button>
          <Button id="save" onClick={onSave}>
            Save
          </Button>
        </div>
      </div>
      {renderTest()}
      {renderToast()}
    </>
  );
};

const Title = ({ children }) => (
  <Typography.Title level={4} style={{ marginBottom: '1.5rem' }}>
    {children}
  </Typography.Title>
);
const GroupInputs = ({ children }) => (
  <Flex vertical gap="large">
    {children}
  </Flex>
);
