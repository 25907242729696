import React from 'react';
import { NodeWrapper } from '.';
import { MessageOutlined } from '@ant-design/icons';
import { InputNumber, Select, Flex } from 'antd';
import { useUpdateNode } from '../hooks';

const options = [
  { value: 'open-last-message', label: 'Open Last Message' },
  { value: 'reply-last-message', label: 'Reply to Last Message' },
  { value: 'clicks-application-link', label: 'Click Application Link' },
  { value: 'completes-application', label: 'Completed Application' },
  { value: 'clicks-custom-link', label: 'Clicks Custom Link' },
  { value: 'unsubscribes', label: 'Unsubscribes' },
];

export const NodeCondition = ({ data, id }) => {
  const { onChangeNode } = useUpdateNode();

  const handleTime = (value) => {
    onChangeNode(id, 'timeframe', value);
  };

  const handleAction = (value) => {
    onChangeNode(id, 'userAction', value);
  };

  const handleValidation = (value) => {
    onChangeNode(id, 'condition', value);
  };

  return (
    <NodeWrapper isFirstNode={data.isFirstNode}>
      <NodeWrapper.Header id={id} Icon={MessageOutlined} deactiveEdit>
        Condition
      </NodeWrapper.Header>
      <Flex vertical gap="middle">
        <div>
          <NodeWrapper.Label strong style={{ fontSize: '1.125rem' }}>
            If recipient
          </NodeWrapper.Label>
          <Flex gap="small">
            <Select
              className="nodrag"
              defaultValue="true"
              value={data.condition || 'true'}
              onChange={handleValidation}
              options={[
                { label: 'Does', value: 'true' },
                { label: 'Does Not', value: 'false' },
              ]}
              style={{
                width: '40%',
              }}
              size="large"
            />
            <Select
              className="nodrag"
              defaultValue="open-last-message"
              value={data.userAction || ''}
              onChange={handleAction}
              options={options}
              style={{
                width: '60%',
              }}
              size="large"
            />
          </Flex>
        </div>
        <div>
          <NodeWrapper.Label strong style={{ fontSize: '1.125rem' }}>
            Within (hours)
          </NodeWrapper.Label>
          <InputNumber
            className="nodrag"
            min={1}
            max={48}
            defaultValue={24}
            value={data.timeframe || 0}
            onChange={handleTime}
            size="large"
            style={{
              width: '100%',
            }}
          />
        </div>
      </Flex>
    </NodeWrapper>
  );
};
