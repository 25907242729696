import React, { Component, Fragment } from 'react';
import { isBlank } from 'util/validate';
import EmojiPicker from './EmojiPicker';
import PersonalizationButton from './PersonalizationButton';
import UpgradeScholaPlanLink from './UpgradeScholaPlanLink';
import ModalUpgradeRequired from 'components/modal-upgrade-plan/ModalUpgradeRequired';
import campaingConstants from '../../utils/CampaingConstants';
import { ImageFormat } from 'util/quill-imageformat';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
import { SectionTitle } from '../shared/input-campaign';

const CTA_OPTS = [
  { label: 'Custom', value: 'custom' },
  { label: 'Request Info', value: 'request_info' },
  { label: 'Schedule a Tour', value: 'schedule_tour' },
  { label: 'Apply Now', value: 'apply_now' },
  { label: 'Enroll Now', value: 'enroll_now' },
  { label: 'None', value: 'none' },
];
const SIGNATURES_OPTS = [
  { label: 'Custom', value: 'custom' },
  { label: 'Best', value: 'Best' },
  { label: 'Regards', value: 'Regards' },
  { label: 'Sincerely', value: 'sincerely' },
  { label: 'Thank you', value: 'thank_you' },
  { label: 'None', value: 'none' },
];

const quillModules = {
  toolbar: {
    container: [
      // [
      //   {
      //     font: [],
      //   },
      // ],
      [{ size: ['8px', '10px', '12px', '14px', '16px', '18px', '20px', '22px', '24px'] }],
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{ align: [] }],
      // [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      // [{ direction: 'rtl' }], // text direction
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['link', 'image'],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      // ['blockquote', 'code-block'],
      ['clean'], // remove formatting button
    ],
    handlers: {
      image() {
        const tooltip = this.quill.theme.tooltip;
        const originalSave = tooltip.save;
        const originalHide = tooltip.hide;

        tooltip.save = function () {
          const range = this.quill.getSelection(true);
          const value = this.textbox.value;
          if (value) {
            this.quill.insertEmbed(range.index, 'image', value, 'user');
          }
        };
        // Called on hide and save.
        tooltip.hide = function () {
          tooltip.save = originalSave;
          tooltip.hide = originalHide;
          tooltip.hide();
        };
        tooltip.edit('image');
        tooltip.textbox.placeholder = 'Embed URL';
      },
    },
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize', 'Toolbar'],
  },
};

var Size = Quill.import('attributors/style/size');
Size.whitelist = ['8px', '10px', '12px', '14px', '16px', '18px', '20px', '22px', '24px'];
Quill.register(Size, true);
Quill.register('modules/imageResize', ImageResize);
Quill.register(Quill.import('attributors/style/align'), true);

// fix image styles inline

Quill.register(ImageFormat, true);

export default class EmailMainBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      upgradeRequired: true,
      protocolSelected: 'http://',
      showModal: false,
      showCode: this.props?.platform === 'sms',
      lastEditorIndex: -1,
    };
    this.inputs = [];
    this.inputEditor;
    this.inputCursorPositions = [];
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.platform !== prevState.platform) {
      return { showCode: nextProps.platform === 'sms' };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.platform !== this.props.platform) {
      this.setState({ showCode: this.props.platform === 'sms' });
    }
  }

  getParamValue = (key) => this.props.campaign?.params?.find((p) => p.key == key)?.value;

  setShowModal = () => {
    this.setState({ showModal: false });
  };

  inputBlur = (key, index) => {
    if (index) {
      this.inputCursorPositions[key] = index || 0;
    } else {
      let input = this.inputs[key];
      this.inputCursorPositions[key] = 0;
      if (input) {
        this.inputCursorPositions[key] = input.selectionStart || 0;
      }
    }
  };

  inputChange = (payload) => {
    let { key, currentValue, newValue } = payload;
    let input = this.inputs[key];
    let inputPosition = -1;
    let value = '';
    if (this.inputCursorPositions[key] !== undefined) {
      inputPosition = this.inputCursorPositions[key];
    }
    if (inputPosition === -1 || inputPosition > currentValue.length) {
      if (currentValue.length === 0) {
        inputPosition = 0;
      } else {
        inputPosition = currentValue.length - 1;
      }
    }

    if (inputPosition + 1 === currentValue.length) {
      value = currentValue + newValue;
    } else {
      value =
        currentValue.substring(0, inputPosition) +
        newValue +
        currentValue.substring(inputPosition, currentValue.length);
    }

    inputPosition += newValue.length;

    this.onChangeParams(key, value);
    if (input) {
      input.focus();
      setTimeout(() => {
        input.setSelectionRange(inputPosition, inputPosition);
      }, 0);
    }
  };

  onChangeParams = (key, value) => {
    const { school, layout, validatePlanInFrontend } = this.props;
    var { upgradeRequired } = this.state;
    const { CTA_TYPE_KEY, SIGNATURE_TYPE_KEY, SIGNATURE_LABEL_KEY } = layout.keys;

    upgradeRequired = validatePlanInFrontend === true && isPremium === false;

    if (
      upgradeRequired &&
      (key === CTA_TYPE_KEY || key === SIGNATURE_TYPE_KEY || key === SIGNATURE_LABEL_KEY) &&
      value === 'custom'
    ) {
      this.setState({
        showModal: true,
      });
    } else {
      this.props.onChangeParams({ key, value });
    }
  };

  renderAlingControls = (key) => {
    const { platform } = this.props;
    const _key = `{{ALING_${key.replace(/{/gi, '').replace(/}/gi, '')}}}`;
    const alingValue = this.getParamValue(_key);
    if (this.props.platform == 'email')
      return (
        <>
          <button
            className={alingValue == 'right' ? 'btn btn-outline-secondary' : 'btn '}
            onClick={() => this.onChangeParams(_key, 'right')}>
            <i className="fa fa-align-right" />
          </button>
          <button
            className={alingValue == 'center' ? 'btn btn-outline-secondary' : 'btn '}
            onClick={() => this.onChangeParams(_key, 'center')}>
            <i className="fa fa-align-center" />
          </button>
          <button
            className={alingValue == 'left' ? 'btn btn-outline-secondary' : 'btn '}
            onClick={() => this.onChangeParams(_key, 'left')}>
            <i className="fa fa-align-left" />
          </button>
          <button
            className={alingValue == 'justify' ? 'btn btn-outline-secondary' : 'btn'}
            onClick={() => this.onChangeParams(_key, 'justify')}>
            <i className="fa fa-align-justify" />
          </button>
        </>
      );
  };

  renderMessage = () => {
    const { layout, platform } = this.props;
    if (!layout) return <></>;

    const { MESSAGE_2_KEY, MESSAGE_3_KEY, MESSAGE_KEY } = layout.keys;
    let columns = 1;
    const messageValue = this.getParamValue(MESSAGE_KEY) || '';
    let messageValue2;
    let messageValue3;

    if (MESSAGE_2_KEY) {
      messageValue2 = this.getParamValue(MESSAGE_2_KEY) || '';
      columns++;
    }
    if (MESSAGE_3_KEY) {
      messageValue3 = this.getParamValue(MESSAGE_3_KEY) || '';
      columns++;
    }

    const _render = (prefix, key, currentValue) => {
      const { school } = this.props;
      const { showCode } = this.state;
      if (this.props.platform !== 'sms') currentValue = (currentValue || '').replace(/\r?\n/g, '<br />');
      return (
        <>
          <>
            {' '}
            <SectionTitle>{`${prefix}Message Content`}</SectionTitle>
            <p className="font-14 font-400 spacing-mb-24">
              Let your recipients know what they're about to read. If you want them to take action, make sure this text
              motivates the reader to click the button below.
            </p>
            <div className="controlBar d-flex flex-row justify-content-end border border-bottom-0">
              {this.props.platform !== 'sms' && (
                <button
                  className="btn"
                  style={{ marginRight: 'auto', fontSize: '.75rem' }}
                  onClick={() => {
                    this.setState({ showCode: !this.state.showCode });
                  }}>
                  <i className={`fa fa-${!showCode ? 'code' : 'eye'}`} />
                </button>
              )}
              {/* {this.renderAlingControls(key)} */}
              {(!showCode || this.props.platform === 'sms') && (
                <>
                  <EmojiPicker
                    className="border-0"
                    onSelect={(newValue) => {
                      if (this.props.platform === 'sms') {
                        this.inputChange({ key, currentValue, newValue });
                      } else {
                        this.inputEditor.editor.clipboard.dangerouslyPasteHTML(
                          this.inputCursorPositions[key],
                          newValue
                        );
                      }
                    }}
                  />
                  <PersonalizationButton
                    loadSchool={this.props.loadSchool}
                    school={school}
                    className="border-0"
                    onSelect={(newValue) => {
                      if (this.props.platform === 'sms') {
                        this.inputChange({ key, currentValue, newValue });
                      } else {
                        this.inputEditor.editor.clipboard.dangerouslyPasteHTML(
                          this.inputCursorPositions[key],
                          newValue
                        );
                      }
                    }}
                  />
                </>
              )}
            </div>
            {this.props.platform !== 'sms' && (
              <ReactQuill
                ref={(inputRef) => (this.inputEditor = inputRef)}
                className="reset-styles"
                style={{ display: showCode ? 'none' : '' }}
                theme="snow"
                value={currentValue}
                modules={quillModules}
                onChangeSelection={(range, source, editor) => {
                  if (range && source === 'user') this.inputBlur(key, range.index + range.length);
                }}
                onBlur={(previousRange, source, editor) => {
                  this.inputBlur(key, previousRange.index + previousRange.length);
                }}
                onChange={(content, delta, source, editor) => {
                  this.onChangeParams(key, content);
                }}
              />
            )}
            <textarea
              className="border"
              style={{ display: !showCode ? 'none' : '' }}
              onChange={(e) => {
                this.onChangeParams(key, e.target.value);
              }}
              value={currentValue}
              onBlur={(e) => this.inputBlur(key)}
              ref={(inputRef) => (this.inputs[key] = inputRef)}
            />
          </>
        </>
      );
    };

    return (
      <>
        {columns == 1 && _render('', MESSAGE_KEY, messageValue)}
        {columns == 2 && (
          <>
            {_render('Left ', MESSAGE_KEY, messageValue)}
            {_render('Right ', MESSAGE_2_KEY, messageValue2)}
          </>
        )}
        {columns == 3 && (
          <>
            {_render('Left ', MESSAGE_KEY, messageValue)}
            {_render('Center ', MESSAGE_2_KEY, messageValue2)}
            {_render('Right ', MESSAGE_3_KEY, messageValue3)}
          </>
        )}
      </>
    );
  };

  getIsReady = () => {
    const { layout } = this.props;
    if (!layout) return false;
    const { MESSAGE_2_KEY, MESSAGE_3_KEY, HEADER_KEY, MESSAGE_KEY } = layout.keys;
    const messageValue = this.getParamValue(MESSAGE_KEY);
    const headerValue = this.getParamValue(HEADER_KEY);
    let columns = 1;
    let messageValue2;
    let messageValue3;

    if (MESSAGE_2_KEY) {
      messageValue2 = this.getParamValue(MESSAGE_2_KEY);
      columns++;
    }
    if (MESSAGE_3_KEY) {
      messageValue3 = this.getParamValue(MESSAGE_3_KEY);
      columns++;
    }

    switch (columns) {
      case 1:
        return !isBlank(headerValue) && !isBlank(messageValue);
      case 2:
        return !isBlank(headerValue) && !isBlank(messageValue) && !isBlank(messageValue2);
      case 3:
        return !isBlank(headerValue) && !isBlank(messageValue) && !isBlank(messageValue2) && !isBlank(messageValue3);
      default:
        return false;
    }
  };

  render() {
    const { school, platform, layout, isPremium, validatePlanInFrontend } = this.props;
    let { upgradeRequired } = this.state;
    if (!layout) return null;
    const {
      HEADER_KEY,
      CTA_TYPE_KEY,
      CTA_CUSTOM_BTN_TEXT_KEY,
      CTA_CUSTOM_URL_KEY,
      CTA_CUSTOM_PROTO_KEY,
      SMS_URL_LINK_KEY,
    } = layout.keys;
    const headerValue = this.getParamValue(HEADER_KEY);
    const ctaType = this.getParamValue(CTA_TYPE_KEY);
    const ctaBtn = this.getParamValue(CTA_CUSTOM_BTN_TEXT_KEY);
    const ctaUrl = this.getParamValue(CTA_CUSTOM_URL_KEY);
    const isReady = this.getIsReady();
    const smsUrlLink = this.getParamValue(SMS_URL_LINK_KEY);
    upgradeRequired = validatePlanInFrontend === true && isPremium === false;

    return (
      <div className="panel">
        {this.renderMessage()}

        {this.props.platform == 'sms' && (
          <>
            <div className="form-row spacing-my-16">
              <div className="form-group col-12 ">
                <label>URL Link</label>
                <input
                  type="text"
                  value={smsUrlLink}
                  onChange={(e) => this.onChangeParams(SMS_URL_LINK_KEY, e.target.value)}
                />
              </div>
            </div>
          </>
        )}
        <ModalUpgradeRequired
          school={school}
          isVisible={this.state.showModal}
          setShowModal={this.setShowModal}
          planToUpgrade="recruitment"
        />
      </div>
    );
  }
}
