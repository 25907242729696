import moment from 'moment';
import days from 'util/days';
import { isBlank } from 'util/validate';
import React, { Component, useEffect } from 'react';
import ScheduleForm from './ScheduleForm';
import { hasPremium } from 'util/schoolUtils';
import campaingSentTo from '../../utils/CampaingsSentTo';
import campaingTriggers from '../../utils/CampaingTriggers';
import UpgradeScholaPlanLink from './UpgradeScholaPlanLink';
import campaingConstants from '../../utils/CampaingConstants';
import { ToolSegmentation } from '../tool-segmentation/tool-segmentation';
import campaingTriggersWithSchedule from '../../utils/CampaingTriggersWithSchedule';
import { campaingContentCheck, toCampaingTypeLabel, toAudienceLanguage } from '../../utils/campaign_utils';
import { Checkbox, Typography } from 'antd';
import { useToggle } from 'hooks/useToggle';

export default class CDResume extends Component {
  constructor(props) {
    super(props);
    this.state = { upgradeRequired: true, showModalInfoIncomplete: false };
  }

  getParamValue = (key) => {
    return this.props.campaign?.params?.find((p) => p.key == key)?.value;
  };

  onChangeParams = (payload) => {
    const { campaign } = this.props;
    const params = campaign.params || [];
    const idx = params.findIndex((p) => p.key == payload.key);

    if (idx > -1) {
      params[idx] = payload;
    } else {
      params.push(payload);
    }

    this.props.onChange('params', params);
  };

  onScheduleChange = (field, value) => {
    const { campaign } = this.props;
    const idx = campaign.params.findIndex((p) => p.key == campaingConstants.SCHEDULE_KEY);

    if (idx > -1) {
      let _value = campaign.params[idx].value;
      _value = JSON.parse(_value);
      _value[field] = value;

      campaign.params[idx].value = JSON.stringify(_value);
    } else {
      let _param = { key: campaingConstants.SCHEDULE_KEY, value: {} };
      let _value = {};
      _value[field] = value;
      _param.value = JSON.stringify(_value);
      campaign.params.push(_param);
    }

    this.props.onChange('params', campaign.params);
  };

  onChangeScheduleRadio = (value) => {
    const { campaign } = this.props;
    const idx = campaign.params.findIndex((p) => p.key == campaingConstants.SCHEDULE_KEY);

    if (idx > -1) {
      let _value = campaign.params[idx].value;
      _value = JSON.parse(_value);
      _value.type = value;
      campaign.params[idx] = { key: campaingConstants.SCHEDULE_KEY, value: JSON.stringify({ ..._value }) };
    } else {
      let _value = { key: campaingConstants.SCHEDULE_KEY, value: JSON.stringify({ type: value }) };
      campaign.params.push(_value);
    }
    this.props.onChange('params', campaign.params);
  };

  hideModalMissingInformation = () => {
    this.setState({ showModalInfoIncomplete: false });
  };

  onChangeSentTo = (e) => {
    const value = e.target.value;
    const { campaign } = this.props;

    this.setParameterValue(campaign, campaingConstants.SENT_TO_KEY, value);

    let trigger = this.getParamValue(campaingConstants.TRIGGER_KEY);
    if (
      campaingSentTo.some((x) => x.value === value) === false &&
      trigger === campaingConstants.TRIGGER_ADD_SCHOLA_SYSTEM_VALUE
    ) {
      this.setParameterValue(campaign, campaingConstants.TRIGGER_KEY, campaingConstants.TRIGGER_NONE_VALUE);
    }

    this.props.onChange('params', campaign.params);
  };

  onChangeTrigger = (value) => {
    const { campaign } = this.props;

    this.setParameterValue(campaign, campaingConstants.TRIGGER_KEY, value);

    this.props.onChange('params', campaign.params);

    if (value == 'none') {
      this.onChangeScheduleRadio('schedule-specific-datetime');
    } else {
      this.onChangeScheduleRadio('send-immediately');
    }

    if (value == 'birthday') {
      this.onChangeScheduleRadio('schedule-specific-datetime');
      this.onChangeSchedule({});
    }
  };

  setParameterValue = (campaign, key, value) => {
    const idx = campaign.params?.findIndex((p) => p.key === key);
    if (idx > -1) {
      campaign.params[idx].value = value;
    } else {
      campaign.params?.push({ key, value });
    }
  };

  handleSegmentationValue = (segmentationValues) => {
    const { campaign } = this.props;

    const transformedData = segmentationValues.map(
      (item) => `${item.field}:${item.type === 'array' ? item.values.join(',') : item.values}`
    );
    const stringData = JSON.stringify(transformedData);

    this.setParameterValue(campaign, '{{segmentation}}', stringData);

    // update the campaign state
    this.props.onChange('params', campaign.params);
  };

  onChangeValueLegagy = (key, value) => {
    const { campaign } = this.props;
    if (key === 'status') {
      this.setParameterValue(campaign, campaingConstants.SENT_TO_KEY, value);
    }
    if (key === 'lead_status_id') {
      this.setParameterValue(campaign, campaingConstants.LEAD_STATUS_ID_KEY, value);
    }

    this.props.onChange('params', campaign.params);
  };

  onChangeSchedule = (value) => {
    const TRIGGER_VALUE = this.getParamValue(campaingConstants.TRIGGER_KEY);
    if (TRIGGER_VALUE === campaingConstants.TRIGGER_BIRTHDAY_VALUE)
      value = {
        recurrence: campaingConstants.RECURRENCE_WEEKLY_VALUE,
        weekly_value_days: days,
        range_recurrence: value.range_recurrence || moment()._d,
        start_time: value.start_time,
        range_recurrence_opt: value.range_recurrence_opt || campaingConstants.RANGE_RECURRENCE_OPT_NO_END_DATE_VALUE,
        range_recurrence_opt_end_by: value.range_recurrence_opt_end_by || '',
        selectTimeZone: value.selectTimeZone || campaingConstants.DEFAULT_TIMEZONE,
      };

    this.onChangeParams({ key: campaingConstants.SCHEDULE_KEY, value: JSON.stringify(value) });
  };

  renderLeadStatuses = () => {
    let { leadStatuses } = this.props;
    if (!leadStatuses || leadStatuses.length === 0) {
      return;
    }
    let leadStatusOptions = leadStatuses.map((leadStatus) => {
      let result = {
        label: leadStatus.name,
        value: leadStatus.id,
      };
      return result;
    });
    leadStatusOptions.push({
      label: 'All',
      value: -1,
    });

    const leadStatusId = this.getParamValue(campaingConstants.LEAD_STATUS_ID_KEY);
    const onChangeLeadStatus = (e) => {
      const value = e.target.value;
      const { campaign } = this.props;
      this.setParameterValue(campaign, campaingConstants.LEAD_STATUS_ID_KEY, value);
      this.props.onChange('params', campaign.params);
    };

    return (
      <>
        <span className="font-600 font-14">Sub Stage:</span>
        <span className="form-row">
          <select
            className="form-control col-12 col-md-8 schola-inputs"
            value={leadStatusId}
            onChange={onChangeLeadStatus}>
            <option value="-2">Select one</option>
            {leadStatusOptions.map((leadStatusOption) => (
              <option key={`${String(leadStatusOption)}-op`} value={leadStatusOption.value}>
                {leadStatusOption.label}
              </option>
            ))}
          </select>
        </span>
      </>
    );
  };

  renderRecipientSchedule = () => {
    const { campaign, leadCountForMarketingCampaigns, leadStatuses } = this.props;
    const scheduleText =
      campaign.type == campaingConstants.RECURRING_EMAIL_CAMPAIGN
        ? 'Select date and time to which it will be sent each week.'
        : 'Schedule for specific date and time';

    let hours = [];
    for (var i = 0; i < 13; i++) {
      hours.push(`0${i}`.slice(-2));
    }
    let minutes = [];
    for (var i = 0; i < 60; i++) {
      minutes.push(`0${i}`.slice(-2));
    }

    const sentToValue = this.getParamValue(campaingConstants.SENT_TO_KEY);
    const leadStatusId = parseInt(this.getParamValue(campaingConstants.LEAD_STATUS_ID_KEY), 10);
    const triggerValue = this.getParamValue(campaingConstants.TRIGGER_KEY);
    const scheduleValue = JSON.parse(this.getParamValue(campaingConstants.SCHEDULE_KEY) || '{}');

    const segmentationValue = JSON.parse(this.getParamValue(campaingConstants.SEGMENTATION) || '[]');
    const audienceLanguage = campaign.audience_language || 'all';

    return (
      <form className="recipients-schedule">
        {/* <div className="row mx-0">
          <p className="font-14 mb-16 col-6 col-md-4">
            <span className="font-600">Send To:</span>
            <span className="form-row">
              <select
                className="form-control col-12 col-md-8 schola-inputs"
                value={sentToValue}
                onChange={this.onChangeSentTo}>
                <option>Select one</option>
                <optgroup label="Leads">
                  {campaingSentTo.map((sentTo, i) => (
                    <option key={`${String(sentTo)}-op${i}`} value={sentTo.value}>
                      {sentTo.label}
                    </option>
                  ))}
                </optgroup>
              </select>
            </span>
          </p>
          {this.renderRecipientsToSend()}
          <div className="col-6 col-md-4 px-0">{this.renderLeadStatuses()}</div>
        </div> */}

        {this.props.isRegularMass === false &&
          (!this.props.isAdvanced ? (
            <div className="triggerSection">
              <h6 className="font-600">Trigger</h6>
              <span className="form-row d-flex flex-column">
                <span className="form-trigger mb-16 schola-inputs">
                  <select
                    className="form-control col-3 schola-inputs"
                    value={triggerValue}
                    onChange={(e) => this.onChangeTrigger(e.target.value)}>
                    {campaingTriggers.map((t) => {
                      return (
                        <option value={t.value} key={`op-${t.value}`}>
                          {t.label}
                        </option>
                      );
                    })}
                  </select>
                </span>

                <p>If selected, the message will be sent immediately when trigger action occurs.</p>
              </span>
            </div>
          ) : (
            <div className="spacing-mb-32">
              <Typography.Title level={5}>Trigger</Typography.Title>
              <ActiveTriggerAdvanced triggerValue={triggerValue} onChangeTrigger={this.onChangeTrigger} />
            </div>
          ))}
        <div className="form-row align-items-center schedule-form">
          <ScheduleForm
            disabled={!campaingTriggersWithSchedule.includes(triggerValue) && !isBlank(triggerValue)}
            isRegularMass={this.props.isRegularMass}
            values={scheduleValue}
            onChange={this.onChangeSchedule}
          />
        </div>
        <ToolSegmentation
          school={this.props.school}
          leadCountForMarketingCampaigns={leadCountForMarketingCampaigns}
          leadStatuses={leadStatuses}
          audienceLanguage={audienceLanguage}
          handleSegmentationValue={this.handleSegmentationValue}
          savedParams={segmentationValue}
          onChangeValueLegagy={this.onChangeValueLegagy}
          prevSentToValue={sentToValue}
          prevLeadStatusId={leadStatusId}
        />
      </form>
    );
  };

  renderRecipientsToSend = () => {
    const { campaign, leadCountForMarketingCampaigns } = this.props;

    if (!leadCountForMarketingCampaigns || leadCountForMarketingCampaigns.length === 0) {
      return '';
    }
    const triger = this.getParamValue(campaingConstants.TRIGGER_KEY);
    const sentToValue = this.getParamValue(campaingConstants.SENT_TO_KEY);
    const leadStatusId = parseInt(this.getParamValue(campaingConstants.LEAD_STATUS_ID_KEY), 10);
    const audienceLanguage = campaign.audience_language || 'all';

    if (triger === campaingConstants.TRIGGER_ADD_SCHOLA_SYSTEM_VALUE) {
      return '';
    }
    let count = 0;
    let countExpired = 0;

    leadCountForMarketingCampaigns.forEach((leadCount) => {
      // status
      let valid = true;
      if (sentToValue !== 'all' && sentToValue !== leadCount.status) {
        valid = false;
      }
      // language
      if (audienceLanguage !== 'all' && leadCount.language !== audienceLanguage) {
        valid = false;
      }
      // lead status id
      if (leadStatusId > 0 && leadCount.lead_status_id !== leadStatusId) {
        valid = false;
      }
      if (valid) {
        count += parseInt(leadCount.count, 10);
        countExpired += parseInt(leadCount.expired, 10);
      }
    });

    // TODO get expired
    return (
      <div className="col-12 col-md-7 mt-24 d-flex align-items-start px-0 pl-md-4" style={{ height: 'fit-content' }}>
        <p className="font-body-primary-bold">
          <i className="fa fa-envelope mr-2" aria-hidden="true" />
          Audience Size:
          <span className="font-headline5-scholagray-regular ml-2">
            {count - countExpired} {countExpired > 0 ? `(${countExpired} excluded, expired leads)` : ''}
          </span>
          <br />
          <span>
            Leads marked as Do Not Call, Duplicate, SPAM, or Wrong Contact Info are automatically deducted from your
            total Audience Size.
          </span>
          <br />
          {sentToValue === 'archived' && (
            <span>
              <br />
              <b>NOTE:</b> Messages sent to Archived Leads are limited to leads added within the past year
            </span>
          )}
        </p>
      </div>
    );
  };

  render() {
    // const { campaign, school, layout, validatePlanInFrontend } = this.props;

    // const campaignChecks = campaingContentCheck(campaign, layout?.params || []);
    // let beheivor = toCampaingTypeLabel(campaign.platform, campaign.type);
    // let audience = toAudienceLanguage(campaign.audience_language);
    // const isPremium = hasPremium(school);
    // const CompleteIcon = <i className="fa fa-check" aria-hidden="true" />;
    // const MissingIcon = <i className="fa fa-question" aria-hidden="true" />;
    // const _upgradeLink = <UpgradeScholaPlanLink school={this.props.school} onUpgradeRequired={() => {}} />;

    // const isSchoolColorsRedy = (checks) => checks && checks.primary_color && checks.secondary_color;
    // const isMediaRedy = (checks) => checks && checks.facebook && checks.instagram && checks.twitter && checks.youtube;
    // const subjectValue = this.getParamValue(campaingConstants.SUBJECT_KEY);
    // const nameValue = this.getParamValue(campaingConstants.NAME_KEY) || school?.name;
    // const emailValue = this.getParamValue(campaingConstants.EMAIL_KEY);

    // let upgradeRequired = validatePlanInFrontend === true && isPremium === false;

    return <div className="email-main-content">{this.renderRecipientSchedule()}</div>;
  }
}

const ActiveTriggerAdvanced = ({ triggerValue, onChangeTrigger }) => {
  const [triggerByLead, toggleTriggerByLead] = useToggle(false);
  useEffect(() => {
    if (triggerValue === 'add_schola_system') {
      toggleTriggerByLead(true);
    }
  }, []);

  const handleTrigger = (value) => {
    toggleTriggerByLead(value);
    const triggerValue = value ? 'add_schola_system' : 'none';
    onChangeTrigger(triggerValue);
  };

  return (
    <>
      <Checkbox checked={triggerByLead} onChange={(e) => handleTrigger(e.target.checked)}>
        Activate the trigger to launch the campaign when a new lead is created in the system.
      </Checkbox>
      <Typography.Paragraph>
        If selected, the campaign will be sent immediately when the trigger action occurs. Otherwise, it will start at
        the scheduled time.
      </Typography.Paragraph>
    </>
  );
};
