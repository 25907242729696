import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './dropdown.scss';

class Dropdown extends Component {
  renderOptions() {
    let opts = this.props.options;
    if (opts.length && typeof opts[0] !== 'object') {
      opts = this.props.options.map((option) => ({ value: option, label: option }));
    }
    return opts.map((opt, index) => (
      <option value={opt.value} disabled={opt.disabled} key={index}>
        {opt.label}
      </option>
    ));
  }

  render() {
    const dropdownClasses = {
      dropdown: true,
      'dropdown--disabled': this.props.disabled,
      [`${this.props.className}`]: this.props.className,
    };

    return (
      <label className={classNames(dropdownClasses)} htmlFor={this.props.id} disabled={this.props.disabled}>
        {this.props.label ? <span className="dropdown-label">{this.props.label}</span> : null}
        <select
          id={this.props.id}
          className="dropdown-select form-control"
          value={this.props.value}
          onChange={(e) => this.props.onChange(e.target.value)}
          disabled={this.props.disabled}>
          {this.renderOptions()}
        </select>
        {this.props.remove ? (
          <a
            href="/#"
            className="dropdown-remove-btn"
            onClick={(e) => {
              e.preventDefault();
              this.props.onRemove();
            }}>
            Remove
          </a>
        ) : null}
      </label>
    );
  }
}

Dropdown.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  id: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Dropdown;
