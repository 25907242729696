import React from 'react';
import { NodeWrapper } from '.';
import { PlusOutlined } from '@ant-design/icons';
import { Select, Flex } from 'antd';
import { useUpdateNode } from '../hooks';
import { useReactFlow, Handle, Position } from '@xyflow/react';

const options = [
  { value: 'send-email', label: 'Send Email' },
  { value: 'send-sms', label: 'Send SMS' },
  { value: 'add-delay', label: 'Add Delay' },
  { value: 'add-condition', label: 'Add Condition' },
  { value: 'end-flow', label: 'End Flow' },
];

export const NodePlaceholder = ({ data, id }) => {
  const { onChangeNode } = useUpdateNode();
  const { setNodes, setEdges } = useReactFlow();

  const handleClick = () => {
    setEdges((edges) => edges.map((edge) => (edge.target === id ? { ...edge, animated: false } : edge)));

    setNodes((nodes) => {
      const updatedNodes = nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: { ...node.data, label: 'New Node' },
            type: 'default',
          };
        }
        return node;
      });
      return updatedNodes;
    });
  };

  const handleAction = (value) => {
    onChangeNode(id, 'nodeSelection', value);
  };

  return (
    <NodeWrapper>
      <NodeWrapper.Header id={id} Icon={PlusOutlined} hideActions>
        New Node
      </NodeWrapper.Header>
      <Flex vertical gap="middle">
        <button onClick={handleClick}>
          <PlusOutlined />
        </button>
        <div>
          <Select
            className="nodrag"
            defaultValue="send-email"
            value={data.nodeSelection || ''}
            onChange={handleAction}
            options={options}
            style={{
              width: '100%',
            }}
            size="large"
          />
        </div>
      </Flex>
      <Handle type="target" style={{ visibility: 'hidden' }} position={Position.Top} isConnectable={false} />
      <Handle type="source" style={{ visibility: 'hidden' }} position={Position.Bottom} isConnectable={false} />
    </NodeWrapper>
  );
};
