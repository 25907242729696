import React, { Component, Fragment } from 'react';
import Modal from 'components/modal/Modal';
import Button from 'components/button/v2/Button';
import { _updateSchoolData } from 'controllers/schools/schools_controller';
import { getResponseValue } from 'util/validate';

const OPTIONS = [
  { label: 'Lead Parent Name', value: '{{lead.parent_name}}' },
  { label: 'Lead Parent First Name', value: '{{lead.parent_first_name}}' },
  { label: 'Lead Parent Last Name', value: '{{lead.parent_last_name}}' },
  { label: 'Lead Student Name', value: '{{lead.student_name}}' },
  { label: 'Lead Grade', value: '{{lead.grade}}' },
  { label: 'Lead Year', value: '{{lead.year}}' },
  { label: 'Lead Country', value: '{{lead.country}}' },
  { label: 'Lead City', value: '{{lead.city}}' },
  { label: 'Lead State', value: '{{lead.state}}' },
  { label: 'Lead Zip', value: '{{lead.zipcode}}' },
  { label: 'School Name', value: '{{school.name}}' },
  { label: 'School Phone', value: '{{school.phone}}' },
  { label: 'School Application Link', value: '{{school.application_url}}' },
];

export default class PersonalizationButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSetPhoneModal: false,
      phone: '',
    };
    this.toggleMenu = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('click', this.onClickOutsideHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClickOutsideHandler);
  }

  onClickOutsideHandler = (event) => {
    if (this.toggleMenu.current && !this.toggleMenu.current.contains(event.target)) this.onHideMenu();
  };

  onHideMenu = () => this.setState({ showMenu: false });

  onAction = (option) => {
    if (option.value === '{{school.phone}}' && !this.props.school.phone) {
      this.setState({ showSetPhoneModal: true });
    } else {
      this.props.onSelect(option.value);
    }
  };

  renderSetPhoneModel = () => {
    const closeSetPhoneModal = () => {
      this.setState({ showSetPhoneModal: false });
    };

    const saveSetPhoneModal = () => {
      let payload = {
        phone: this.state.phone,
      };
      _updateSchoolData(this.props.school.id, payload).then((res) => {
        if (res.error) {
          this.setState({
            showSmsTest: false,
            toast: {
              type: 'error',
              message:
                'Oops! There seems to be a slight problem on our end. Please try again in a few minutes. If this problem continues, please contact our support team to describe the issue.',
            },
          });
        } else {
          closeSetPhoneModal();
          this.props.onSelect('{{school.phone}}');
          if (this.props.loadSchool) {
            this.props.loadSchool();
          }
        }
      });
    };

    return (
      <Modal className="set-phone-modal" isVisible={this.state.showSetPhoneModal} onClose={closeSetPhoneModal}>
        <div className="modal-content">
          <div className="modal-body">
            <div className="d-flex justify-content-center mb-3">
              <div className="icon-container icon-blue d-flex justify-content-center align-items-center">
                <i className="fa fa-info-circle" aria-hidden="true" />
              </div>
            </div>
            <h2 className="font-headline2-primary-bold text-center mb-2">Oops!</h2>
            <p className="font-subtitle2-scholagray-bold">
              Seems like you don't have a phone number registered for your school. Don't worry insert the phone number
              below:
            </p>
            <div className="mt-4">
              <label htmlFor="phonenumber" className="font-small-scholagray-regular mb-2">
                Enter Phone Number:
              </label>
              <input
                type="tel"
                className="form-control"
                placeholder="Enter a valid phone number here"
                value={this.state.phone}
                onChange={(e) => this.setState({ phone: e.target.value })}
              />
              <p className="font-xsmall-light-regular mt-1">Format: (507) 323 2343 - (State code) and the number</p>
            </div>
          </div>
          <div className="modal-footer">
            <Button color="bluelegacy" theme="outlined" onClick={closeSetPhoneModal}>
              Cancel
            </Button>
            <Button color="bluelegacy" onClick={saveSetPhoneModal} disabled={this.state.phone === ''}>
              Save
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  render() {
    const { showMenu } = this.state;
    return (
      <>
        <div className="input-group-append" ref={this.toggleMenu}>
          <button
            style={{ fontSize: '12px' }}
            className="btn dropdown-toggle"
            onClick={() => this.setState({ showMenu: true })}>
            Insert Personalization
          </button>
        </div>
        {showMenu && (
          <div className="actions-content-menu">
            <ul>
              {OPTIONS.map((opt, i) => {
                return (
                  <li key={`option-${i}`} className="item cursor-pointer" onClick={() => this.onAction(opt)}>
                    {opt.label}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {this.renderSetPhoneModel()}
      </>
    );
  }
}
