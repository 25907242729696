const defaultGrades = ['Pre-K', 'TK', 'HS', 'K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
class SchoolGradesUtil {
  intGrade(grade) {
    let ordinal = 0;
    switch (grade) {
      case 'Pre-K':
        ordinal = -3;
        break;
      case 'TK':
        ordinal = -2;
        break;
      case 'HS':
        ordinal = -1;
        break;
      case 'K':
        ordinal = 0;
        break;
      default:
        ordinal = parseInt(grade, 10);
        break;
    }
    return ordinal;
  }

  getGrades(school) {
    let selectedGrades = [];

    if (school) {
      if (school.grades) {
        const _grades = school.grades.split(',');
        _grades.map((grade) => {
          if (grade !== '') {
            selectedGrades.push({ value: grade, label: grade === 'HS' ? 'Head Start' : grade });
          }
        });
      } else {
        const minIndex = defaultGrades.findIndex((x) => x === school.min_grade);
        const maxIndex = defaultGrades.findIndex((x) => x === school.max_grade);
        const _grades = defaultGrades.slice(minIndex, maxIndex + 1);
        _grades.map((grade) => {
          selectedGrades.push({ value: grade, label: grade === 'HS' ? 'Head Start' : grade });
        });
      }

      if (selectedGrades) {
        selectedGrades = selectedGrades.sort((a, b) =>
          this.intGrade(a.value) > this.intGrade(b.value) ? 1 : this.intGrade(b.value) > this.intGrade(a.value) ? -1 : 0
        );
      }
    }
    return selectedGrades;
  }

  getAllGradesOptions = () => {
    let gradesOptions = [];
    defaultGrades.map((grade) => {
      gradesOptions.push({ value: grade, label: grade === 'HS' ? 'Head Start' : grade });
    });
    gradesOptions = gradesOptions.sort((a, b) =>
      this.intGrade(a.value) > this.intGrade(b.value) ? 1 : this.intGrade(b.value) > this.intGrade(a.value) ? -1 : 0
    );
    return gradesOptions;
  };
}

export default new SchoolGradesUtil();
