import React from 'react';
import { NodeWrapper } from '.';
import { MessageOutlined } from '@ant-design/icons';
import { InputNumber, Select, Typography, Col, Row } from 'antd';
import { useUpdateNode } from '../hooks';

export const NodeTimer = ({ data, id }) => {
  const options = [
    { value: 'minutes', label: 'Minutes' },
    { value: 'hours', label: 'Hours' },
    { value: 'days', label: 'Days' },
    { value: 'weeks', label: 'Weeks' },
  ];

  const { onChangeNode } = useUpdateNode();

  const handleTime = (value) => {
    onChangeNode(id, 'time', value);
  };

  const handleTimelapse = (value) => {
    onChangeNode(id, 'timeframe', value);
  };

  return (
    <NodeWrapper isFirstNode={data.isFirstNode}>
      <NodeWrapper.Header id={id} Icon={MessageOutlined} deactiveEdit>
        Wait
      </NodeWrapper.Header>
      <div>
        <Typography.Paragraph strong className="font-16">
          Delay Duration
        </Typography.Paragraph>
        <Row align="middle" gutter={8}>
          <Col span={8}>
            <InputNumber
              className="nodrag"
              min={1}
              max={10}
              defaultValue={3}
              value={data.time}
              onChange={handleTime}
              size="large"
              style={{
                width: '100%',
              }}
            />
          </Col>
          <Col span={16}>
            <Select
              className="nodrag"
              defaultValue="hours"
              value={data.timeframe}
              onChange={handleTimelapse}
              options={options}
              style={{
                width: '100%',
              }}
              size="large"
            />
          </Col>
        </Row>
      </div>
    </NodeWrapper>
  );
};
