export default [
  '(GMT-11:00) Pago Pago',
  '(GMT-10:00) Hawaii Time',
  '(GMT-08:00) Pacific Time',
  '(GMT-08:00) Pacific Time - Tijuana',
  '(GMT-07:00) Mountain Time',
  '(GMT-07:00) Mountain Time - Arizona',
  '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan',
  '(GMT-06:00) Central Time',
  '(GMT-06:00) Central Time - Mexico City',
  '(GMT-06:00) Central Time - Regina',
  '(GMT-06:00) Guatemala',
  '(GMT-05:00) Bogota',
  '(GMT-05:00) Eastern Time',
  '(GMT-05:00) Lima',
  '(GMT-04:30) Caracas',
  '(GMT-04:00) Atlantic Time - Halifax',
  '(GMT-04:00) Guyana',
  '(GMT-04:00) La Paz',
  '(GMT-03:00) Buenos Aires',
  '(GMT-03:00) Godthab',
  '(GMT-03:00) Montevideo',
  '(GMT-03:30) Newfoundland Time - St. Johns',
  '(GMT-03:00) Santiago',
  '(GMT-02:00) Sao Paulo',
  '(GMT-02:00) South Georgia',
  '(GMT-01:00) Azores',
  '(GMT-01:00) Cape Verde',
  '(GMT+00:00) Casablanca',
  '(GMT+00:00) Dublin',
  '(GMT+00:00) Lisbon',
  '(GMT+00:00) London',
  '(GMT+00:00) Monrovia',
  '(GMT+01:00) Algiers',
  '(GMT+01:00) Amsterdam',
  '(GMT+01:00) Berlin',
  '(GMT+01:00) Brussels',
  '(GMT+01:00) Budapest',
  '(GMT+01:00) Central European Time - Belgrade',
  '(GMT+01:00) Central European Time - Prague',
  '(GMT+01:00) Copenhagen',
  '(GMT+01:00) Madrid',
  '(GMT+01:00) Paris',
  '(GMT+01:00) Rome',
  '(GMT+01:00) Stockholm',
  '(GMT+01:00) Vienna',
  '(GMT+01:00) Warsaw',
  '(GMT+02:00) Athens',
  '(GMT+02:00) Bucharest',
  '(GMT+02:00) Cairo',
  '(GMT+02:00) Jerusalem',
  '(GMT+02:00) Johannesburg',
  '(GMT+02:00) Helsinki',
  '(GMT+02:00) Kiev',
  '(GMT+02:00) Moscow-01 - Kaliningrad',
  '(GMT+02:00) Riga',
  '(GMT+02:00) Sofia',
  '(GMT+02:00) Tallinn',
  '(GMT+02:00) Vilnius',
  '(GMT+03:00) Istanbul',
  '(GMT+03:00) Baghdad',
  '(GMT+03:00) Nairobi',
  '(GMT+03:00) Minsk',
  '(GMT+03:00) Riyadh',
  '(GMT+03:00) Moscow+00 - Moscow',
  '(GMT+03:30) Tehran',
  '(GMT+04:00) Baku',
  '(GMT+04:00) Moscow+01 - Samara',
  '(GMT+04:00) Tbilisi',
  '(GMT+04:00) Yerevan',
  '(GMT+04:30) Kabul',
  '(GMT+05:00) Karachi',
  '(GMT+05:00) Moscow+02 - Yekaterinburg',
  '(GMT+05:00) Tashkent',
  '(GMT+05:30) Colombo',
  '(GMT+06:00) Almaty',
  '(GMT+06:00) Dhaka',
  '(GMT+06:30) Rangoon',
  '(GMT+07:00) Bangkok',
  '(GMT+07:00) Jakarta',
  '(GMT+07:00) Moscow+04 - Krasnoyarsk',
  '(GMT+08:00) China Time - Beijing',
  '(GMT+08:00) Hong Kong',
  '(GMT+08:00) Kuala Lumpur',
  '(GMT+08:00) Moscow+05 - Irkutsk',
  '(GMT+08:00) Singapore',
  '(GMT+08:00) Taipei',
  '(GMT+08:00) Ulaanbaatar',
  '(GMT+08:00) Western Time - Perth',
  '(GMT+09:00) Moscow+06 - Yakutsk',
  '(GMT+09:00) Seoul',
  '(GMT+09:00) Tokyo',
  '(GMT+09:30) Central Time - Darwin',
  '(GMT+10:00) Eastern Time - Brisbane',
  '(GMT+10:00) Guam',
  '(GMT+10:00) Moscow+07 - Magadan',
  '(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk',
  '(GMT+10:00) Port Moresby',
  '(GMT+10:30) Central Time - Adelaide',
  '(GMT+11:00) Eastern Time - Hobart',
  '(GMT+11:00) Eastern Time - Melbourne, Sydney',
  '(GMT+11:00) Guadalcanal',
  '(GMT+11:00) Noumea',
  '(GMT+12:00) Majuro',
  '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy',
  '(GMT+13:00) Auckland',
  '(GMT+13:00) Fakaofo',
  '(GMT+13:00) Fiji',
  '(GMT+13:00) Tongatapu',
  '(GMT+14:00) Apia',
];
