import React, { Component, Fragment } from 'react';
import ImageUploader from './ImageUploader';
import { isBlank } from 'util/validate';
import { SectionTitle } from '../shared/input-campaign';

export default class EmailImage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getParamValue = (key) => this.props.campaign?.params?.find((p) => p.key == key)?.value;

  getIsReady = (keys) => {
    let isReady = true;
    keys = keys.filter((k) => k);
    keys.map((key) => {
      if (isBlank(this.getParamValue(key))) isReady = false;
    });
    return isReady;
  };

  renderImg = (imageKey, title, showInfo) => {
    const logoUrl = this.getParamValue(imageKey);
    return (
      <div>
        <SectionTitle>{title}</SectionTitle>
        {showInfo && <p className="">A picture's worth 1,000 words, right?. Make sure it’s related to the message.</p>}
        <ImageUploader
          id={imageKey}
          URL={logoUrl}
          image_key={imageKey}
          school_id={this.props.school_id}
          remove_text="Remove Image"
          update_text="Update Image"
          campaign={this.props.campaign}
          onSuccess={this.props.onChangeParams}
          onRemoveImage={() => this.props.onRemoveParam(imageKey)}
          title={title}
        />
      </div>
    );
  };

  render() {
    const { layout } = this.props;
    if (!layout) return <></>;
    const { EMAIL_IMAGE_1_KEY, EMAIL_IMAGE_2_KEY, EMAIL_IMAGE_3_KEY } = layout.keys;

    const isReady = this.getIsReady([EMAIL_IMAGE_1_KEY, EMAIL_IMAGE_2_KEY, EMAIL_IMAGE_3_KEY]);

    return (
      <div className="panel">
        {this.renderImg(EMAIL_IMAGE_1_KEY, EMAIL_IMAGE_2_KEY ? 'Left Column Image' : 'Image', !EMAIL_IMAGE_2_KEY)}
        {EMAIL_IMAGE_2_KEY &&
          this.renderImg(
            EMAIL_IMAGE_2_KEY,
            EMAIL_IMAGE_3_KEY ? 'Center Column Image' : 'Rigth Column Image',
            !EMAIL_IMAGE_2_KEY
          )}
        {EMAIL_IMAGE_3_KEY && this.renderImg(EMAIL_IMAGE_3_KEY, 'Rigth Column Image', !EMAIL_IMAGE_2_KEY)}
      </div>
    );
  }
}
