import { useState } from 'react';
import { Modal } from 'antd';
import { Button } from '..';
import moment from 'moment';
import { EditOutlined, DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { useToast } from 'hooks/useToast';
import classNames from 'classnames';
import { _deleteLeadSourceCost } from 'controllers/leads/leads_controller';
import { TablePagination } from './table-pagination';
import styles from './historical-campaign-table.module.scss';

const HistoricalCampaignTable = ({
  campaigns,
  onDelete = () => {},
  onEdit = () => {},
  campaignSource,
  pagination,
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
}) => {
  const { toast } = useToast();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [campaignToDelete, setCampaignToDelete] = useState(null);

  const handleDeleteClick = (campaign) => {
    setCampaignToDelete(campaign);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (campaignToDelete) {
      onDelete(campaignToDelete.id);
      _deleteLeadSourceCost(campaignToDelete.id).then((res) => {
        if (res) {
          toast({
            title: 'Campaign Deleted',
            description: 'The historical campaign has been removed.',
          });
        }
      });
      setDeleteDialogOpen(false);
      setCampaignToDelete(null);
    }
  };

  const handleEdit = (campaign, source) => {
    onEdit(campaign, source);
    toast({
      title: 'Edit Campaign',
      description: 'You can now edit the campaign details.',
    });
  };

  const getSourceNameById = (source_id) => {
    const source = Array.isArray(campaignSource) ? campaignSource.find((s) => s.id === source_id) : null;
    return source ? source.name : '';
  };

  const calculatePerStudentSpend = (spend, studentsRecruited) => {
    if (studentsRecruited <= 0) return 0;
    const perStudent = spend / studentsRecruited;
    return Math.round(perStudent * 100) / 100; // Round to 2 decimal places
  };

  return (
    <>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHeader}>
              <th className={styles.tableHeaderCell}>Campaign Source</th>
              <th className={styles.tableHeaderCell}>Start Date</th>
              <th className={styles.tableHeaderCell}>End Date</th>
              <th className={styles.tableHeaderCell}>Total Spend</th>
              <th className={styles.tableHeaderCell}>Students Recruited</th>
              <th className={styles.tableHeaderCell}>Per Student Spend</th>
              <th className={styles.tableHeaderCell}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {campaigns.map((campaign) => (
              <tr key={campaign.id} className={styles.tableRow}>
                <td className={styles.tableCell}>
                  <div>
                    <div className={classNames(styles.tableCellPrimary, 'spacing-mb-8')}>
                      {getSourceNameById(campaign.sources_id)}
                    </div>
                    <div className={styles.tableCellSecondary}>
                      Created on {new Date(campaign.created_at).toLocaleDateString()}
                    </div>
                  </div>
                </td>
                <td className={styles.tableCell}>{moment.utc(campaign.start_date).format('MM/DD/YYYY')}</td>
                <td className={styles.tableCell}> {moment.utc(campaign.end_date).format('MM/DD/YYYY')}</td>
                <td className={styles.tableCell}>${campaign.amount}</td>
                <td className={styles.tableCell}>{campaign.students_recruited}</td>
                <td className={styles.tableCell}>
                  ${calculatePerStudentSpend(campaign.amount, campaign.students_recruited).toLocaleString()}
                </td>
                <td className={styles.actionsCell}>
                  <Button
                    type="default"
                    className={styles.button}
                    icon={<EditOutlined />}
                    onClick={() => handleEdit(campaign, getSourceNameById(campaign.sources_id))}>
                    Edit
                  </Button>
                  <Button
                    danger
                    type="default"
                    className={`${styles.button}`}
                    onClick={() => handleDeleteClick(campaign)}
                    icon={<DeleteOutlined />}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <TablePagination
          initialPagination={pagination}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
        />
      </div>
      <Modal
        open={deleteDialogOpen}
        onCancel={() => setDeleteDialogOpen(false)}
        onOk={handleDeleteConfirm}
        className={styles.modal}
        okText="Delete"
        okButtonProps={{ danger: true }}>
        <div className={styles.modalContent}>
          <WarningOutlined style={{ color: 'orange', fontSize: '1.3rem' }} />
          <div className={styles.modalDescription}>
            <h4>Delete Historical Campaign</h4>
            <p className={styles.decription}>
              Are you sure you want to delete the
              <span className={styles.sourceTag}>{getSourceNameById(campaignToDelete?.sources_id)}</span>
              history?
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HistoricalCampaignTable;
