import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button/Button';
import styles from './table-pagination.module.scss';

export const TablePagination = ({ initialPagination, setCurrentPage, currentPage, setPageSize, pageSize }) => {
  const [pagination, setPagination] = useState({ rowCount: 0, page: 1, pageSize: 10 });
  /* const [currentPage, setCurrentPage] = useState(1); */
  /* const [pageSize, setpageSize] = useState(10); */
  const totalPages = Math.ceil(pagination?.rowCount / pageSize);
  const startItem = (currentPage - 1) * pageSize + 1;
  const endItem = Math.min(currentPage * pageSize, pagination?.rowCount);

  useEffect(() => {
    setPagination(initialPagination);
  }, [initialPagination]);

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setPagination({
        ...pagination,
        page: currentPage - 1,
        pageSize,
      });
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setPagination({
        ...pagination,
        page: currentPage + 1,
        pageSize,
      });
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <div className={styles.container}>
      <div>
        Displaying {startItem || ''}-{endItem || ''} of {pagination.rowCount}
      </div>
      <div className={styles.items}>
        <ul className={styles.pagination}>
          <li>
            <select
              className="page-size__select"
              name="page_size"
              onChange={(e) => {
                const value = Number(e.target.value);
                setPageSize(value);
                if (value * currentPage > pagination.rowCount) {
                  setCurrentPage(Math.ceil(pagination.rowCount / value));
                }
              }}
              value={pageSize}>
              {[10, 20, 50, 100, 500].map((t, i) => {
                return (
                  <option key={t} value={t}>
                    {t}
                  </option>
                );
              })}
            </select>
          </li>
          <li>Per Page</li>
          <li>
            <Button variant="outline" size="sm" onClick={handlePrevClick} disabled={currentPage === 1}>
              Prev
            </Button>
          </li>
          <li className={styles.pageNumber}>{currentPage}</li>
          <li>
            <Button color="secondary" size="sm" onClick={handleNextClick} disabled={currentPage === totalPages}>
              Next
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
};

TablePagination.propTypes = {
  initialPagination: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
};
