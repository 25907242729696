import { forwardRef } from 'react';
import { DownOutlined, CheckOutlined } from '@ant-design/icons';
import { useToggle } from 'hooks/useToggle';
import { useClickOutside } from 'hooks/useClickOutside';
import classNames from 'classnames';
import styles from './selector.module.scss';

export const Selector = ({
  id = '',
  placeholder,
  options,
  currentSelected,
  onHandleChange,
  multiSelect = false,
  onClearSelection,
  onSelectAll,
  classNameList,
  className,
}) => {
  // own state to manage dropdown
  const [isDropOpen, toggleDrop] = useToggle(false);
  const ref = useClickOutside(() => isDropOpen && toggleDrop());

  const onChange = (item) => {
    onHandleChange(item);
    !multiSelect && toggleDrop();
  };

  const onClear = () => {
    onClearSelection();
    toggleDrop();
  };

  return (
    <SelectorContainer id={id} className={classNames(styles.selector, classNameList || '', className || '')} ref={ref}>
      <Display
        placeholder={placeholder}
        toggleDrop={toggleDrop}
        className={
          multiSelect ? (currentSelected.length > 0 ? styles.active : '') : currentSelected ? styles.active : ''
        }
      />

      {isDropOpen && (
        <Droplist className={classNameList || ''}>
          {options.map((item) => {
            const selectedIItem = multiSelect ? currentSelected.includes(item.id) : currentSelected === item.id;

            return (
              <li key={item.id} className={`${styles.item} ${selectedIItem && styles.selected}`}>
                <input
                  type={multiSelect ? 'checkbox' : 'radio'}
                  className={styles.item_radio}
                  id={`source_option_${item.id}`}
                  value={item.id}
                  label={item.name}
                  checked={selectedIItem}
                  onChange={() => onChange(item)}
                />

                {selectedIItem && <CheckOutlined className={styles.check} />}
                <label className={styles.label} htmlFor={`source_option_${item.id}`}>
                  <span>{item.name}</span>
                </label>
              </li>
            );
          })}
          {onClearSelection && currentSelected.length > 0 && (
            <button type="button" className={styles.clearButton} onClick={onClear}>
              Clear All
            </button>
          )}
          {multiSelect && onSelectAll && (
            <button type="button" className={styles.clearButton} onClick={onSelectAll}>
              Select All
            </button>
          )}
        </Droplist>
      )}
    </SelectorContainer>
  );
};

const SelectorContainer = forwardRef(({ children, className }, ref) => {
  return (
    <div className={`${styles.selector} ${className || ''}`} ref={ref}>
      {children}
    </div>
  );
});

const Display = ({ toggleDrop, placeholder, className, ...props }) => {
  return (
    <div className={`${styles.display} ${className}`} onClick={toggleDrop} {...props}>
      <span className={styles.placeholder}>{placeholder}</span>
      <span className={styles.icon}>
        <DownOutlined />
      </span>
    </div>
  );
};

const Droplist = ({ children, className }) => {
  return <ul className={`${styles.droplist} ${className || ''}`}>{children}</ul>;
};

Selector.Container = SelectorContainer;
Selector.Display = Display;
Selector.Droplist = Droplist;
