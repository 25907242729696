import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/modal/Modal';
import Cropper from 'react-cropper';
import Button from 'components/button/v2/Button';

const container = {
  minWidth: 300,
  minHeight: 400,
};

const ModalCrop = (props) => {
  return (
    <Modal
      className="upgrade-schoola-plan"
      isVisible={props.showModal}
      onClose={() => props.closeModal()}
      showCloseIcon={false}
      title="Crop the image">
      {props.imageFile.imageSrc && (
        <>
          <div style={container}>
            <div>
              <div className="cropper">
                <Cropper
                  ref={props.cropperRef}
                  src={props.imageFile.imageSrc}
                  style={{ height: 500, width: '100%' }}
                  // Cropper.js options
                  initialAspectRatio={props.aspectRatioValue}
                  viewMode={1}
                  guides
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive
                  restore
                />
              </div>
            </div>
          </div>
          <div className="buttonContainer">
            <Button type="button" color="primary" theme="outlined" onClick={() => props.closeModal()}>
              Cancel
            </Button>
            <Button type="button" color="secondary" onClick={() => props.saveCropImage()}>
              Save
            </Button>
          </div>
        </>
      )}
      {!props.imageFile.imageSrc && (
        <div className="loadingIcon">
          <img
            src="https://scholamatch-static.s3-us-west-2.amazonaws.com/LoadingAnimation200Opt.gif"
            alt="schola loading"
          />
          <p className="loadingIconText">Loading...</p>
        </div>
      )}
    </Modal>
  );
};

ModalCrop.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  aspectRatioValue: PropTypes.number,
  imageFile: PropTypes.object,
};

ModalCrop.defaultProps = {
  showModal: false,
  closeModal: () => {},
  imageFile: {
    imageSrc: null,
    imageFilename: null,
    imageResult: null,
    imageError: null,
  },
};

export default ModalCrop;
