import { Button as AntButton } from 'antd';
import styles from './button.module.scss';

export const Button = ({ children, ...props }) => {
  return (
    <AntButton
      {...props}
      className={props.className || styles.button}
      type={props.type || 'primary'}
      style={{ fontWeight: 600 }}>
      {children}
    </AntButton>
  );
};
