import { useEffect } from 'react';
import { Select } from './select-param';
import campaingConstants from '../../utils/CampaingConstants';

const SIGNATURES_OPTS = [
  { label: 'Custom', value: 'custom' },
  { label: 'Best', value: 'Best' },
  { label: 'Regards', value: 'Regards' },
  { label: 'Sincerely', value: 'sincerely' },
  { label: 'Thank you', value: 'thank_you' },
  { label: 'None', value: 'none' },
];

export const Signature = ({ school, layout, onChangeParams, getParamValue }) => {
  const { SIGNATURE_TYPE_KEY, SIGNATURE_LABEL_KEY, SIGNATURE_NAME_KEY } = layout.keys;

  const type = getParamValue(SIGNATURE_TYPE_KEY) || '';
  const label = getParamValue(SIGNATURE_LABEL_KEY) || '';
  const name = getParamValue(SIGNATURE_NAME_KEY) || '';

  useEffect(() => {
    if (name === '' && school?.name) {
      setTimeout(() => onChangeParams({ key: SIGNATURE_NAME_KEY, value: school?.name }), 10);
    }
    if (type === '' || !type) {
      setTimeout(
        () => onChangeParams({ key: SIGNATURE_TYPE_KEY, value: campaingConstants.SIGNATURE_TYPE_KEY_DEFAULT }),
        10
      );
    }
  }, [name, type, school]);

  return (
    <div>
      <div className="form-row">
        <Select
          id="signatureType"
          label="Type of Signature"
          options={SIGNATURES_OPTS}
          value={type}
          fieldParam={SIGNATURE_TYPE_KEY}
          onChangeParams={(e) => {
            let label = SIGNATURES_OPTS.find((opt) => opt.value === e.value)?.label;
            if (e.value === campaingConstants.SIGNATURE_TYPE_KEY_CUSTOM_VALUE) {
              label = '';
            }
            onChangeParams({ key: SIGNATURE_LABEL_KEY, value: label });
            onChangeParams({ key: SIGNATURE_TYPE_KEY, value: e.value });
          }}
        />
        {type !== 'none' && (
          <div className="form-group col-12 col-md-6">
            <label>Custom Signature Name</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => onChangeParams({ key: SIGNATURE_NAME_KEY, value: e.target.value })}
            />
          </div>
        )}
      </div>
      <div className="form-row mb-24">
        {type === campaingConstants.SIGNATURE_TYPE_KEY_CUSTOM_VALUE && (
          <div className="form-group col-12 col-md-6">
            <label>Custom Signature Label</label>
            <input
              type="text"
              value={label}
              onChange={(e) => onChangeParams({ key: SIGNATURE_LABEL_KEY, value: e.target.value })}
            />
          </div>
        )}
      </div>
    </div>
  );
};
