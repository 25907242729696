import { useEffect, useState } from 'react';
import styles from './campaign-builder.module.scss';
import { useCampaign } from '../flow-designer/hooks';
import { Button, CampaignName } from '../../components';
import { Typography, Flex, Badge, Tabs } from 'antd';
import { ApartmentOutlined } from '@ant-design/icons';
import { browserHistory } from 'react-router';
import { SectionTitle } from 'components/pages/campaigns/components/shared/input-campaign';
import { FlowDesigner } from '..';

// campaign builder
// wrap children
//  - FlowDesigner
//  - CampaignDetails
export const CampaignBuilder = ({ params, location, children }) => {
  const { campaign_id: campaignId, id: schoolId } = params;
  const { pathname } = location;

  // call campaign
  const { initCampaign, campaign, updateCampaignValue, getParamValue, setCampaignParam } = useCampaign();
  useEffect(() => {
    initCampaign(schoolId, campaignId);
  }, []);

  // handle tab section
  const [tab, setTab] = useState('audience');

  return (
    <main className={styles.container}>
      <Flex align="center" justify="space-between">
        <CampaignName name={campaign?.name} updateCampaignValue={updateCampaignValue} />
        <CampaignStatus status={campaign.status} />
      </Flex>
      <Typography.Paragraph className={styles.step}>
        {!pathname.endsWith('flow') ? 'Step 1 of 2: Campaign Details' : 'Step 2 of 2: Message Sequence'}
      </Typography.Paragraph>
      <section className={styles.layout}>
        <aside>
          <Flex align="center" justify="space-between">
            <SectionTitle>Campaign Builder</SectionTitle>
            <CampaignStatus status={campaign?.status} />
          </Flex>

          <Tabs
            defaultActiveKey={tab}
            items={[
              { key: 'audience', label: 'Audience', icon: <ApartmentOutlined /> },
              { key: 'summary', label: 'Summary', icon: <ApartmentOutlined /> },
            ]}
            onChange={setTab}
          />
        </aside>

        <FlowDesigner params={params} />
      </section>
    </main>
  );
};

const CampaignFooter = ({ children, primaryLabel, onClick, secondaryLabel, secondaryOnClick }) => {
  return (
    <Flex align="center" justify="space-between" style={{ marginTop: '1rem' }}>
      <Button size="large" type="default" onClick={() => browserHistory.goBack()}>
        Back
      </Button>
      <Flex align="center" gap="middle" style={{ marginTop: '1rem' }}>
        {secondaryLabel && (
          <Button type="default" size="large" onClick={secondaryOnClick}>
            {secondaryLabel}
          </Button>
        )}
        <Button onClick={onClick} size="large">
          {primaryLabel}
        </Button>
      </Flex>
    </Flex>
  );
};

const CampaignStatus = ({ status }) => {
  const statusTypes = {
    Active: 'success',
    Draft: 'processing',
    Paused: 'warning',
    Archived: 'default',
  };

  return <Badge status={statusTypes[status]} text={status} />;
};

CampaignBuilder.Status = CampaignStatus;
CampaignBuilder.Footer = CampaignFooter;
