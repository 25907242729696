import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLeadCount } from 'redux/campaigns/campaigns-reducer';

export const useSegmentationCount = ({ leadCountForMarketingCampaigns, filteringParams = [], audienceLanguage }) => {
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();

  const containsValidation = (currentValues, value) => {
    return currentValues.includes(value);
  };

  useEffect(() => {
    if (leadCountForMarketingCampaigns) {
      let filteredLeads = [...leadCountForMarketingCampaigns];

      // filter by campaign audience language
      if (audienceLanguage !== 'all') {
        filteredLeads = filteredLeads.filter((item) => item.language === audienceLanguage);
      }

      for (const param of filteringParams) {
        const isArray = Array.isArray(param.values);
        if (isArray && param.values.length > 0) {
          filteredLeads = filteredLeads.filter((item) => containsValidation(param.values, item[param.field]));
        }

        if (typeof param.values === 'boolean') {
          filteredLeads = filteredLeads.filter((item) => item[param.field] === param.values);
        }
      }

      // get the total leads after filters applied
      const totalCount = filteredLeads.reduce((acc, lead) => acc + parseInt(lead.count, 10), 0);

      setCount(totalCount);
      // dispatch to global state to manage advanced viz:
      dispatch(setLeadCount(totalCount));
    }
  }, [leadCountForMarketingCampaigns, filteringParams, audienceLanguage]);

  return { count };
};
