import React, { Component, Fragment } from 'react';
import ImageUploader from './ImageUploader';
import { isBlank } from 'util/validate';

export default class EmailSchoolBrand extends Component {
  constructor(props) {
    super(props);
    this.state = { upgradeRequired: true };
  }

  getParamValue = (key) => this.props.campaign?.params?.find((p) => p.key == key)?.value;

  onChangeParams = (key, value) => this.props.onChangeParams({ key, value });

  checkReady = () => {
    const { campaign, layout } = this.props;
    const { SCHOOL_LOGO_KEY } = layout?.keys;
    const BRANDING_KEYS = {
      SCHOOL_LOGO_KEY,
    };
    let isReady = false;
    if (campaign) {
      const { params } = campaign;
      if (params) {
        const keys = Object.getOwnPropertyNames(BRANDING_KEYS);
        isReady = keys.length > 0;
        keys.map((key) => {
          if (!isBlank(this.getParamValue(key))) isReady = false;
        });
      }
    }

    return isReady;
  };

  render() {
    let { upgradeRequired } = this.state;
    const { school, layout, isPremium, validatePlanInFrontend } = this.props;
    const isReady = this.checkReady();
    const { SCHOOL_LOGO_KEY } = layout?.keys;

    upgradeRequired = validatePlanInFrontend === true && isPremium === false;

    return (
      <div className="school-logo-content">
        <ImageUploader
          size="logo"
          id="logo-img"
          URL={this.getParamValue(SCHOOL_LOGO_KEY)}
          main_url={this.getParamValue(SCHOOL_LOGO_KEY)}
          campaign={this.props.campaign}
          school_id={this.props.school_id}
          image_key={SCHOOL_LOGO_KEY}
          onSuccess={this.props.onChangeParams}
          disabled={false}
          remove_text="Remove Logo"
          update_text="Update Logo"
          onRemoveImage={() => this.props.onRemoveParam(SCHOOL_LOGO_KEY)}
        />
      </div>
    );
  }
}
