import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { browserHistory } from 'react-router';
import { useStatus } from 'hooks/useStatus';
import { onCloseSaveModal } from 'redux/campaigns/campaigns-reducer';
import { Modal, notification, Typography, Divider, Tag } from 'antd';
import { CampaignBuilder } from '../..';
import { getParam } from '../utils/getParam';
import { Button } from '../../../components';
import { useCampaign } from '../hooks';
import moment from 'moment';
import campaingConstants from 'components/pages/campaigns/utils/CampaingConstants';
import timeZones from 'util/time-zones';

export const SaveUpdatedCampaign = ({ schoolId, campaignId }) => {
  const { isModalSaveOpen, leadCount } = useSelector((state) => state.campaigns);

  const { campaign, updateCampaign, setCampaignStatus } = useCampaign();

  const isPublish = useMemo(() => campaign.status === 'Active', [campaign]);

  const { status, onLoading, onSuccess, onError } = useStatus();

  const handleUpdateCampaign = async (status) => {
    const updateStatus = !isPublish ? 'Active' : undefined;
    console.log({ updateStatus });

    let feedback = {
      message: 'Campaign Saved',
      description: 'The campaign has been updated successfully.',
    };
    try {
      onLoading();
      await updateCampaign(schoolId);

      if (status) {
        feedback.message = `Campaign ${status} Successfully`;
        await setCampaignStatus(schoolId, campaignId, status);
      }
      notification.success(feedback);
      onSuccess();
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'The campaign had an error.',
      });
    }
  };

  const onInactiveCampaign = () => handleUpdateCampaign('Paused');

  const onPublishCampaign = () => handleUpdateCampaign(!isPublish ? 'Active' : undefined);

  useEffect(() => {
    if (status.success) {
      onCloseSaveModal();
      goToDashboard();
    }
  }, [status]);

  const goToDashboard = () => browserHistory.push(`/admin/v2/schools/${schoolId}/communication-hub/campaigns-center`);

  const schedule = useMemo(() => {
    if (campaign?.params) {
      const scheduleParam = getParam(campaign.params, '{{schedule}}');
      if (scheduleParam) {
        const scheduleTime = JSON.parse(scheduleParam.value);
        const localTime = getDateTimeFromUtcToLocal(
          scheduleTime.start_date,
          scheduleTime.start_time,
          scheduleTime.selectTimeZone
        );

        return {
          isTriggered: scheduleTime?.type === 'send-immediately',
          ...localTime,
        };
      }

      return null;
    }
  }, [campaign.params]);

  const isRecurring = useMemo(() => {
    if (campaign?.params) {
      const isRecurring = getParam(campaign?.params, 'isRecurringAdvanced');
      if (isRecurring) {
        return isRecurring.value;
      }

      return false;
    }
  }, [campaign]);

  return (
    <Modal
      open={isModalSaveOpen}
      onCancel={onCloseSaveModal}
      width={500}
      title="Confirm and Save"
      footer={[
        <Button type="default" onClick={onCloseSaveModal}>
          Cancel
        </Button>,
        isPublish && (
          <Button type="default" onClick={onInactiveCampaign}>
            Pause Campaign
          </Button>
        ),
        <Button onClick={onPublishCampaign}>{isPublish ? 'Save' : 'Publish'} Campaign</Button>,
      ]}>
      <Divider orientation="left" orientationMargin="0">
        <span style={{ marginRight: '1rem' }}>Current Campaign Status:</span>
        <CampaignBuilder.Status status={campaign?.status} />
      </Divider>

      <div style={{ marginBottom: '2rem' }}>
        <Typography.Paragraph>
          This campaign will be launch{' '}
          <Typography.Text strong>{!isRecurring ? 'only one time' : 'recurrently'}</Typography.Text>
        </Typography.Paragraph>
        {schedule?.isTriggered ? (
          <Typography.Paragraph>This campaign will display everytime a lead enters to Schola</Typography.Paragraph>
        ) : (
          <Typography.Paragraph>
            The campaign will display on
            <Typography.Text strong> {schedule?.date}</Typography.Text> at
            <Typography.Text strong>
              {' '}
              {schedule?.time}/{schedule?.timeZone}
            </Typography.Text>
          </Typography.Paragraph>
        )}

        <DetailItem label="Language" value={campaign?.audience_language} />
        <DetailItem label="Platform" value={campaign?.platform} />
        <DetailItem label="Leads Targeting" value={leadCount} />
      </div>
    </Modal>
  );
};

const DetailItem = ({ label, value }) => (
  <>
    <Typography.Text>{label}: </Typography.Text>
    <Tag bordered={false} color={value ? 'processing' : 'error'} style={{ textTransform: 'capitalize' }}>
      {value}
    </Tag>
  </>
);

const getDateTimeFromUtcToLocal = (date, time, timeZone) => {
  let isValidDate = true;
  timeZone = getTimeZone(timeZone);
  let offsetMinutes = getOffsetMinutes(timeZone);
  if (!time) {
    time = campaingConstants.DEFAULT_START_TIME;
  }
  if (moment.utc(time, campaingConstants.FORMAT_TIME, true).isValid() === false) {
    time = campaingConstants.DEFAULT_START_TIME;
  }
  if (moment.utc(date, campaingConstants.FORMAT_DATE, true).isValid() === false) {
    if (moment.utc(date).isValid() === true) {
      date = moment.utc(date).format(campaingConstants.FORMAT_DATE);
    } else {
      isValidDate = false;
      date = moment.utc().format(campaingConstants.FORMAT_DATE);
    }
  }

  let dateTime = moment.utc(`${date} ${time}`, `${campaingConstants.FORMAT_DATE} ${campaingConstants.FORMAT_TIME}`);
  if (offsetMinutes !== 0) {
    dateTime = dateTime.add(offsetMinutes, 'minutes');
  }

  return {
    date: isValidDate === true ? dateTime.format(`${campaingConstants.FORMAT_DATE}`) : '',
    time: dateTime.format(`${campaingConstants.FORMAT_TIME}`),
    timeZone,
  };
};

const getTimeZone = (selectTimeZone) => {
  if (selectTimeZone && selectTimeZone != '') {
    var timeZone = timeZones.find((x) => x === selectTimeZone);
    if (timeZone) {
      return timeZone;
    }
  }
  return campaingConstants.DEFAULT_TIMEZONE;
};

const getOffsetMinutes = (timeZone) => {
  timeZone = getTimeZone(timeZone);
  let utcSignal = timeZone.substring(4, 5);
  let hours = timeZone.substring(5, 10);
  let minutes = moment.duration(hours).asMinutes();

  if (minutes > 0) {
    if (utcSignal === '-') {
      minutes *= -1;
    }
  }
  return minutes;
};
