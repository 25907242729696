import { useMemo } from 'react';
import { Flex, Card } from 'antd';
import { MailOutlined, MessageOutlined, FieldTimeOutlined, BranchesOutlined } from '@ant-design/icons';
import styles from '../flow-designer.module.scss';

const nodeTypes = [
  { id: 'email', type: 'emailNode', label: 'Send Email', icon: MailOutlined },
  { id: 'sms', type: 'smsNode', label: 'Send SMS', icon: MessageOutlined },
];
const nodeBase = [
  { id: 'timer', tag: 'LOGIC', type: 'timerNode', label: 'Wait', icon: FieldTimeOutlined },
  { id: 'condition', tag: 'TIMING', type: 'conditionNode', label: 'Condition', icon: BranchesOutlined },
];

export const SelectorNode = ({ campaignType, onNodeClick, isEmptyNode }) => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  const nodeOptions = useMemo(() => {
    if (campaignType === 'hybrid') return nodeTypes;

    return nodeTypes.filter((node) => campaignType === node.id);
  }, [campaignType]);

  return (
    <Card title="Campaign Nodes" className={styles.nodeSelector}>
      <Flex vertical gap="small">
        <p>ACTIONS</p>
        {nodeOptions.map((node) => {
          const Icon = node.icon;
          return (
            <div
              key={node.id}
              type="default"
              onClick={() => onNodeClick(node.type)}
              className={styles.nodeButton}
              draggable
              onDragStart={(e) => onDragStart(e, node.type)}>
              <Icon style={{ color: '#0099cc' }} />
              <span>{node.label}</span>
            </div>
          );
        })}
        {!isEmptyNode &&
          nodeBase.map((node) => {
            const Icon = node.icon;
            return (
              <div key={node.id}>
                <p>{node.tag}</p>
                <div
                  type="default"
                  onClick={() => onNodeClick(node.type)}
                  className={styles.nodeButton}
                  draggable
                  onDragStart={(e) => onDragStart(e, node.type)}>
                  <Icon style={{ color: '#0099cc' }} />
                  <span>{node.label}</span>
                </div>
              </div>
            );
          })}
      </Flex>
    </Card>
  );
};
